import React, {useEffect, useState} from 'react';
import {Row, Col, Card, Button, Radio, InputNumber, message } from 'antd';
import {
    PlayCircleFilled,
    PauseCircleFilled,
    RestFilled,
} from '@ant-design/icons';
import {HongBtns} from '../../common/component/btns/btns.tsx';
import HongLeft from '../../pages/settting/hong.tsx';
import { saveDatas, getSettings, addHongName } from '../../store/hong/imp.ts';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from '../../common/global.ts';
import { setHongName } from '../../store/setting/imp.ts';
import { testConnect } from '../../store/kdevice/imp.ts';
import products from '../../common/data/product.ts'

let listenTime = 0;
let optionStore:any = [];
let radio:any = {};
let down:boolean = false;

const Performanc = ({t}:any) => {
    const [checkHong, setCheckHong] = useState<string>("");
    const [option, setOption] = useState([]);
    const [begin, setBegin] = useState<boolean>(false);
    const [checked, setChecked] = useState({
        checked: false,
        value: 0
    });
    const hongSetting = useSelector((state:any) => {
        return state.hong.settings;
    });

    const {productId}:any = useSelector((state:any) => state.kdevice);

    const {checkedProFile, settings} = useSelector((state:any) => {
        return state.settingReducer;
    });

    const hong_space_size = useSelector((state:any) => {
        return state.kdevice.hong_space_size;
    });
    
    const dispatch = useDispatch();

    async function save() {
        if (!hongSetting || !hongSetting?.length) return;
        const tag = await testConnect();
        if (!tag?.connect) {
            dispatch(tag);
            return;
        }
        setBegin(false);

        debounce('hongsave', () => {
            let nowhongSetting = hongSetting.map((item:any) => {
                return {
                    ...item,
                    option: checkHong === item.key ? option : item.option,
                }
            });
            const result = saveDatas(nowhongSetting, hong_space_size, products.getRequstDataLen(productId));
            dispatch(result);
        }, 100)
        
    }

    useEffect(() => {
        function downlisten(e:any) {
            if (!begin || down) return;
            let options = optionStore;
            let nowdate = new Date().getTime();
            if ( options?.length) {
                options[options?.length-1].time = radio?.checked?radio?.value:nowdate - listenTime;
            }
            optionStore = [...options, {
                key: e.code,
                option: 'down',
                time: 1,
            }];
            setOption(optionStore);

            listenTime = nowdate;
            down = true;
        }
    
        function uplisten(e:any) {
            if (!begin) return;

            let options = optionStore;
            let nowdate = new Date().getTime();
            if ( options?.length) {
                options[options?.length-1].time = radio?.checked?radio?.value:nowdate - listenTime;
            }
            optionStore = [...options, {
                key: e.code,
                option: 'up',
                time: 1,
            }];
            setOption(optionStore);
    
            listenTime = nowdate;
            down = false;
            console.log(optionStore, 'optionStore')
        }

        const setlisten = function () {    
            document.addEventListener('keydown', downlisten);
            document.addEventListener('keyup', uplisten);
        }
        const removelisten = function() {
            document.removeEventListener('keydown', downlisten);
            document.removeEventListener('keyup', uplisten);
        }

        if (begin) setlisten();
        else removelisten();

        return () => {
            removelisten();
        }
    }, [begin]);

    useEffect(() => {
        if (!hongSetting || !hongSetting?.length) {
            setOption([]);
            optionStore = [];
            setCheckHong(``);
            return;
        }
        if (checkHong) {
            console.log(hongSetting)
            const now = hongSetting.find((item:any) => item.key === checkHong);
            setOption(JSON.parse(JSON.stringify(now?.option||[])));
            optionStore = JSON.parse(JSON.stringify(now?.option||[]));
        } else {
            setCheckHong(hongSetting[0].key);
            setOption([]);
            optionStore = [];
        } 
    }, [checkHong, hongSetting]);

    useEffect(() => {
        const arr = settings.find(({key}:any) => key === checkedProFile);
        let hongname = arr?.hongsetting;

        if (hongname) {
            dispatch(getSettings(hongname));
        } else {
            const name = `${new Date().getTime()}`;
            dispatch(addHongName(name));
            dispatch(setHongName(name));
            setOption([]);
            optionStore = [];
        }
    }, [checkedProFile]);

    const changeHongChecked = (val:any) => {
        if (option && option?.length) {
            const now = hongSetting.find((item:any) => item.key === checkHong);
            if (JSON.stringify(now?.option) === JSON.stringify(option)) {
                setCheckHong(val);
            } else {
                alert('option has change, please save');
            }
        } else {
            setCheckHong(val);
        }
    }

    return <Row className='hong' gutter={[8, 0]}>
        <Col span={6}>
            <Card>
                <HongLeft
                    t={t}
                    checkHong = {checkHong}
                    setChecked = {changeHongChecked}
                />
            </Card>
        </Col>
        <Col span={18}>
            <Card onMouseLeave={()=> {if (begin) setBegin(false)}}>
                <Row className='hongOtion'>
                    <Col span={4}>
                        {!begin ?<Button icon={<PlayCircleFilled />} onClick={async () => {

                            if (!checkHong) {
                                message.info(t(`pleasechoosehong`));
                                return
                            }
                            setBegin(true);
                        }}/>:
                        <Button icon={<PauseCircleFilled />} onClick={() => {
                            setBegin(false);
                        }}/>}
                        <Button icon={<RestFilled />} onClick={() => {
                            setOption([]);
                            optionStore = [];
                        }}/>
                    </Col>
                    <Col span={16} style={{textAlign:"center"}}>
                        <>
                            <Radio checked={checked?.checked}
                                onClick={() => {
                                    radio = {...checked, 'checked': !checked?.checked};
                                    setChecked(radio)}
                                }
                                />
                                <span style={{marginLeft: 5}}>{t(`fixedTime`)}</span>
                            <InputNumber
                                style={{marginLeft: 5}}
                                value={checked?.value}
                                min={1}
                                max={65535}
                                step={1}
                                onChange={(val) => {
                                    radio = {...checked, 'value': val||1};
                                    setChecked(radio)}
                                } 
                                />
                            <span style={{marginLeft: 5}}>ms</span>
                        </>
                    </Col>
                    <Col span={4} style={{textAlign: 'right'}}>
                        <Button onClick={save}>{t(`save`)}</Button>
                    </Col>
                </Row>
                <Row className='hongOtion-body'>
                    <Col span={24}>
                        {HongBtns(option, setOption, t)}
                    </Col>
                </Row>
            </Card>
        </Col>
    </Row>
}

Performanc.displayName = 'Performanc';
export default Performanc;