import React, {useEffect, useState} from 'react';
import {Row, Col, Switch , Slider, Button  } from 'antd'
import {LineBtns} from '../../common/component/btns/btns.tsx'
import ColorPickers from '../../common/component/colorPicker/colorPickers.tsx'
import KeyBoard from '../keys/keyBoard.tsx';

import {ArrowRightOutlined, ArrowLeftOutlined} from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux';
import {funFormat} from '../../common/request/format.ts'
import { 
    setTyfun,
    tySendReport,
    readLightRect,
    installLightRect,
    setLightRect,
    updateLightRect,
    reqcount,
    testConnect
} from '../../store/kdevice/imp.ts';
import { setTySetting } from '../../store/setting/imp.ts'
import { tenTo16, debounce } from '../../common/global.ts';
import { keyTypeNum } from '../../common/text.ts';

const light_level = {
    key: 'light_level',
    text: 'light_level',
    type: 'Slider-text',
    data: '100%'
};
const light_speed = {
    key: 'light_speed',
    text: 'light_speed',
    type: 'btns',
    data: [
        {
            label: '1',
            value: '4',
        },
        {
            label: '2',
            value: '3',
        },
        {
            label: '3',
            value: '2',
        },
        {
            label: '4',
            value: '1',
        },
        {
            label: '5',
            value: '0',
        }
    ],
};
const light_trend = {
    key: 'light_trend',
    text: 'light_trend',
    type: 'arrow',
    data: [
        {
            label: 'arrowtoright',
            value: '0',
            icon: <ArrowRightOutlined />
        },
        {
            label: 'arrowtoleft',
            value: '1',
            icon: <ArrowLeftOutlined />
        }
    ],
};
const light_trend_1 = {
    key: 'light_trend',
    text: 'light_trend',
    type: 'arrow',
    data: [
        {
            label: 'uptodown',
            value: '0',
            icon: <ArrowRightOutlined />
        },
        {
            label: 'downtoup',
            value: '1',
            icon: <ArrowLeftOutlined />
        }
    ],
};
const light_trend_2 = {
    key: 'light_trend',
    text: 'light_trend',
    type: 'arrow',
    data: [
        {
            label: 'clockwise',
            value: '0',
            icon: <ArrowRightOutlined />
        },
        {
            label: 'anticlockwise',
            value: '1',
            icon: <ArrowLeftOutlined />
        }
    ],
};
const light_trend_3 = {
    key: 'light_trend',
    text: 'light_trend',
    type: 'arrow',
    data: [
        {
            label: 'frominsideout',
            value: '0',
            icon: <ArrowRightOutlined />
        },
        {
            label: 'fromoutsidein',
            value: '1',
            icon: <ArrowLeftOutlined />
        }
    ],
};
const light_RGB = {
    key: 'light_RGB',
    text: 'light_RGB',
    type: 'switch'
};
// const light

const order:any = [
    {
        label: 'wave',
        value: 0,
        icon: '',
        effect: [light_level, light_speed, light_trend, light_RGB],
    },
    {
        label: 'cloud',
        value: 1,
        icon: '',
        effect: [light_level, light_speed, light_trend, light_RGB],
    },
    {
        label: 'neon',
        value: 2,
        icon: '',
        effect: [light_level, light_speed, light_trend_1],
    },
    {
        label: 'vortex',
        value: 3,
        icon: '',
        effect: [light_level, light_speed, light_trend_2, light_RGB],
    },
    {
        label: 'flower',
        value: 4,
        icon: '',
        effect: [light_level, light_speed],
    },
    {
        label: 'fly',
        value: 5,
        icon: '',
        effect: [light_level, light_speed, light_RGB],
    },
    {
        label: 'flow',
        value: 6,
        icon: '',
        effect: [light_level, light_speed, light_RGB],
    },
    {
        label: 'rain',
        value: 7,
        icon: '',
        effect: [light_level, light_speed, light_RGB],
    },
    {
        label: 'mixColor',
        value: 8,
        icon: '',
        effect: [light_level, light_speed],
    },
    {
        label: 'breathe',
        value: 9,
        icon: '',
        effect: [light_level, light_speed, light_RGB],
    },
    {
        label: 'static',
        value: 10,
        icon: '',
        effect: [light_level, light_speed, light_RGB],
    },
    {
        label: 'ripple',
        value: 11,
        icon: '',
        effect: [light_level, light_speed, light_RGB],
    },
    {
        label: 'click',
        value: 12,
        icon: '',
        effect: [light_level, light_speed, light_RGB],
    },
    {
        label: 'reaction',
        value: 13,
        icon: '',
        effect: [light_level, light_speed, light_RGB],
    },
    {
        label: 'speed',
        value: 14,
        icon: '',
        effect: [light_level, light_trend_3],
    },
    {
        label: 'scan',
        value: 15,
        icon: '',
        effect: [light_level, light_RGB],
    },
    {
        label: 'change',
        value: 16,
        icon: '',
        effect: [light_level],
    },
    {
        label: 'custom',
        value: 17,
        icon: '',
        effect: [light_level],
    },
    {
        label: 'close',
        value: 18,
        icon: '',
        effect: [],
    },
    // {
    //     label: '音乐律动',
    //     value: '20',
    //     icon: '',
    //     effect: effectSettingdata,
    // },
]

// const line = [
//     {
//         icon: 'pause',
//         text: '静态'
//     },
//     {
//         icon: 'pause',
//         text: '波浪'
//     },
//     {
//         icon: 'pause',
//         text: '循环'
//     }
// ]

const effectSetting = ({
        text, 
        type,
        data,
        key,
    }:any,
    dataval:any,
    onClick: Function,
    tag: number,
    t:Function) => {
    const nowval = dataval && dataval?.[key] ? dataval?.[key]:0;

    if (type === 'Slider-text') {
        return <Row key={`${key}`} gutter={[16, 16]} style={{marginTop: `${tag? 3 : 5}vh`}}>
            <Col span={4} style={{lineHeight:'30px'}}>
                <span>{`${t(text)}`}</span>
            </Col>
            <Col span={16}>
                <Slider defaultValue={nowval} min={0} max={99} step={1}  onChange={(value) => onClick ? onClick(value) : null}/>
            </Col>
            <Col span={4} style={{lineHeight:'30px'}}>
                <span>{`${data}`}</span>
            </Col>
        </Row>
    } else if (type === 'arrow') {
        return <Row key={`${key}`} gutter={[16, 16]} style={{marginTop: `${tag? 3 : 5}vh`}}>
            <Col span={4} style={{lineHeight:'30px'}}>
                <span>{`${t(text)}`}</span>
            </Col>
            {
                data.map((item:any, i:number) => <Col span={10} key={`${i+1}`}>
                    <Button className={item?.value===`${nowval}` ? 'cell-Btn-checked':'cell-Btn'} icon={item?.icon}
                        onClick={() => onClick ? onClick(item?.value) : null}>   
                    </Button>
                    <span style={{marginLeft:16, color: '#999'}}>{t(item?.label)}</span>
                </Col>)
            }
            
        </Row>
    } else if (type === 'btns') {
        return <Row key={`${key}`} gutter={[16, 16]} style={{marginTop: `${tag? 3 : 5}vh`}}>
            <Col span={4} style={{lineHeight:'30px'}}>
                <span>{`${t(text)}`}</span>
            </Col>
            <Col span={20}>
                <Row>
                {
                    data.map((item:any, i:number) => <Col span={4} key={`${i+1}`}>
                        <Button className={item?.value===`${nowval}` ? 'cell-Btn-checked':'cell-Btn'}
                            onClick={() => onClick ? onClick(item?.value) : null}>
                            {`${item?.label}`} 
                        </Button>
                    </Col>)
                }
                </Row>
                <Row>
                    <Col span={16} style={{}}>
                        <span style={{marginLeft:13, color: '#999'}}>{`${t(`slow`)}`}</span>
                    </Col>
                    <Col span={4}>
                        <span style={{marginLeft:13, color: '#999'}}>{`${t(`fast`)}`}</span>
                    </Col>
                </Row>
            </Col>
        </Row>
    } else if (type === 'switch') {
        return <Row key={`${key}`} gutter={[16, 16]} style={{marginTop: `${tag? 3 : 5}vh`}}>
            <Col span={4} style={{lineHeight:'30px'}}>
                <span>{`${t(text)}`}</span>
            </Col>
            <Col span={20}>
                <Switch checked={nowval?true:false} size="default" onChange={(bl) => onClick(bl?1:0)} />
            </Col>
        </Row>
    } 
}

const LightSetting = ({t}:any) => {
    const nowdata = useSelector((state:any) => state.kdevice.setting);
    const default_key_Rect = useSelector((state:any) => state.kdevice.default_key_Rect);
    const light_rect = useSelector((state:any) => state.kdevice.light_rect);
    const checkedKey = useSelector((state:any) => state.settingReducer.checkedKey);
    const [color, setColor] = useState({});
    const [needColorSelect, setNeedColorSelect] = useState(true);
    const dispatch = useDispatch();
    const [advancedData, setAdvancedData] = useState({
        'light_mode': 0,
        'light_level': 99,
        'light_speed':3,
        'light_trend':0,
        'light_RGB':0,
    });
    const [effectSettingdata, setEffectSettingdata] = useState([]);
    
    const setinit = (data:string[]) => {
        setTimeout(() => {
          if (reqcount === 0) {
            dispatch(setTyfun(data));
            dispatch(setTySetting(data)); // 存储通用信息到prodile store
          } else {
            setinit(data);
          }
        }, 200);
    }

    const lightInit = () => {
        setTimeout(() => {
        //   alert(`succe${reqcount}`)
          if (reqcount === 0) {
            dispatch(installLightRect())
          } else {
            lightInit();
          }
        }, 200);
    }

    const lightUpdate = (data:any) => {
        setTimeout(() => {
        //   alert(`succe${reqcount}`)
          if (reqcount === 0) {
            dispatch(updateLightRect(data))
          } else {
            lightUpdate(data);
          }
        }, 200);
    }
    
    const handClick = async (key:string, data:any) => {
        const tag = await testConnect();
        if (!tag?.connect) {
            dispatch(tag);
            return;
        }

        const index = funFormat.findIndex((type) => type.includes(key));
        let newarr:any = [...nowdata];
        if (index > 0) newarr[index] = tenTo16(data);
        if (key === 'light_RGB') {
            setNeedColorSelect(!data);
            const {r, g, b}:any = color;
            if (r && g && b && !data) {
                newarr[index+1] = tenTo16(r);
                newarr[index+2] = tenTo16(g);
                newarr[index+3] = tenTo16(b);
            }
            
        } 
   
        tySendReport(newarr);
        setinit(newarr);
    }

    const changeColors = async (c:any={}, nowKey:string) => {
        const tag = await testConnect();
        if (!tag?.connect) {
            dispatch(tag);
            return;
        }
        const {r, g, b}:any = c;
        const codeValue:any = keyTypeNum?.getCodeValue(); 
        if (advancedData?.["light_mode"] === 17) {
            if (!nowKey || !codeValue[nowKey]) return;
            //灯光自定义模式
            let lightRect = [...light_rect];
            // alert(JSON.stringify(lightRect));
            const codes:string[] = codeValue[nowKey]; 
            let Index:number = -1;
            let i = 0;
            while (Index < 0 && i < default_key_Rect.length) {
                if (parseInt(codes[0], 16) === default_key_Rect[i] &&
                    parseInt(codes[1], 16) === default_key_Rect[i+1] &&
                    parseInt(codes[2], 16) === default_key_Rect[i+2]
                ) {
                    lightRect[i] = r;
                    lightRect[i+1] = g;
                    lightRect[i+2] = b;
                    Index = i;
                    
                }
                i = i + 3;
            }

            setLightRect(lightRect);
            lightUpdate(lightRect);
        } else {
            // 设置模块单色颜色
            const index = funFormat.findIndex((type) => type.includes('light_RGB'));
            let newarr:any = [...nowdata];
            if (index > 0) {
                newarr[index] = '0x00';
                newarr[index+1] = tenTo16(r);
                newarr[index+2] = tenTo16(g);
                newarr[index+3] = tenTo16(b);
            }

            tySendReport(newarr);
            setinit(newarr);
        }
    }
    const changeLightMode = async (mode:number) => {
        debounce('setcolor', async () => {
            const tag = await testConnect();
            if (!tag?.connect) {
                dispatch(tag);
                return;
            }
    
            let newarr:any = [...nowdata];
            newarr[1] = tenTo16(mode);
    
            if ([17].includes(mode)) {
                newarr[5] = tenTo16(0);
            }
    
            tySendReport(newarr);
            setinit(newarr);
    
            // 特殊灯光效果设置
            if (mode === 17) {
                setTimeout(() => {
                    readLightRect();
                    lightInit();
                }, 200)
            }
        }, 500)
       
    }

    useEffect(() => {
        let param:any = {};
        funFormat.forEach((key:string, i:number) => {
            param[key] = parseInt(nowdata[i], 16);
        });
        setAdvancedData(param);

        // 灯光设置为自定义则初始化灯光
        if (param?.["light_mode"] === 17 && (!light_rect || !light_rect?.length)) {
            readLightRect();
            lightInit();
        }

        // 根据模式显示控制版面
        const attr:never[] = order[param?.["light_mode"]]?.effect;
        if (attr && attr?.length) setEffectSettingdata([...attr]);
        else setEffectSettingdata([]);

        // 控制颜色选择模块显示隐藏
        if ((attr && attr?.length &&
            attr.findIndex((ele:any) => ele?.key === 'light_RGB') > -1 &&
            param?.["light_RGB"] === 0) ||
            param?.["light_mode"] === 17) {
            setNeedColorSelect(true);
        } else {
            setNeedColorSelect(false);
        }
    }, [nowdata]);

    return <div className='linesetting'>
        <div className='board'>
            <KeyBoard t={t} changeDefaultColor= {(k:string) => changeColors(color, k)}/>
        </div>
       <div className='linesetting-body'>
            <Row gutter={[60, 16]}>
                <Col span={needColorSelect ? 15 : 24}>
                    <Row gutter={[30,0]} style={{height: '30vh', overflowY:'scroll'}}>
                        <Col span = {12} >
                            <div><span>{`${t(`lightvalid`)}`}</span></div>
                            <div style={{height: 16}}></div>
                            {LineBtns(
                                order,
                                advancedData?.["light_mode"],
                                t,
                                (data:number) => {
                                    changeLightMode(data);
                                }   
                            )}
                        </Col>
                        <Col span = {12}>
                        {
                            effectSettingdata.map((item:any, i: number) => {
                                return effectSetting(
                                    item,
                                    advancedData,
                                    (data:string) => {
                                        debounce(item.key, () => {
                                            handClick(item.key, data)
                                        }) ;
                                    },
                                    i,
                                    t)
                            }) 
                        }
                        </Col>
                    </Row>
                </Col>
                {
                    needColorSelect ? <Col span = {9}>
                        <ColorPickers t={t}
                            data={advancedData}
                            color={color}
                            setColor = {(c:any)=>{
                            if (!c || (!c?.r && c?.r !== 0 && !c?.g && !c?.b)) return;
                            debounce('light_RGB_1', () => {
                                changeColors(c, checkedKey);
                            }) ;
                            setColor(c)
                        }}/>
                    </Col>:null
                }
            </Row>
           
       </div>
    </div>
}

export default LightSetting;