import React, { useState } from 'react';
import {InputBtns} from '../../../common/component/btns/btns.tsx'
import { useSelector } from 'react-redux';
import { Button, InputNumber } from 'antd'
import {tenTo16} from '../../../common/global.ts'
import {keyTypeNum} from '../../text.ts'

const SetHong = (props:any) => {
    const [checked, setChecked] = useState('');
    const [value, setValue] = useState(1);
    const {checkedProFile, settings} = useSelector((state:any) => state.settingReducer);

    const changF = () => {
        const settingItem = settings.find(({key}:any) => key === checkedProFile);
        const hongname = settingItem?.hongsetting;
        let arr = hongname ? JSON.parse(localStorage.getItem(hongname)||'[]'):[];
        return arr&&arr?.length ? [...arr].map((item, i) => ({
            key: i,
            text: `${item?.name}`,
            // value: `${i+1}`
            value: `${i}`
        })):[]
    }
    const handClick = (data:any) => {
        setChecked(data);
    }

    const setClick = (data:any) => {
        if (props?.onClick && checked) {
            props.onClick("", [keyTypeNum.get('hong1'), tenTo16(parseInt(checked)), data]);
        }
    }
    const sureClick = () => {
        if (!value) return;
        if (props?.onClick && checked) {
            props.onClick("", [keyTypeNum.get('hong2'), tenTo16(parseInt(checked)), tenTo16(value)]);
        }
    }

    return <div className='key-board'>
            <div style={{textAlign: 'left',  float:'left',marginRight:`${checked?'260px':'20px'}`}}>
                {InputBtns(changF(), (data:any) => handClick && handClick(data))}
            </div>
            <div style={{width:'240px', float:'right', display:`${checked?'block':'none'}`}}>
                <div className='label' style={{marginBottom: '8px'}}>{props?.t(`pleaseCheckedHongWay`)}</div>
                <Button className='cell-Btn2' onClick={() => setClick('0x00')}>{props?.t(`normalStop`)}</Button>
                <Button className='cell-Btn2' onClick={() => setClick('0x01')}>{props?.t(`releaseStop`)}</Button>
                <Button className='cell-Btn2' onClick={() => setClick('0x02')}>{props?.t(`triggerStop`)}</Button>

                <div className='label' style={{marginBottom: '8px',marginTop:'24px'}} >{props?.t(`playnum`)}</div>
                <InputNumber
                    value={value}
                    // className='cell-Btn2'
                    min={1} max={100}
                    step={1}
                    onChange={(val) => setValue(val||1)}
                    placeholder={props?.t(`limitCount100`)}/>
                <Button className='cell-Btn2' onClick={sureClick}>{props?.t(`sureSetting`)}</Button>
            </div>
    </div>
}
SetHong.displayName = 'SetHong';
export default SetHong;
