import Type from "./type.ts";

const initialState = {
    connect: false,
    key_Rect: [],
    default_key_Rect: [],
    key_rect_size: 56,
    hong_space_size: 256, 
    device: {
        name: '',
    },
    setting: [
        '00','00','63','02','00','00','ff','00',
        '00','00','00','00','00','00','00','78',
        '00','58','02','78','00','58','02','58',
        '02','00','00','00','00','00','00','00',
        '00','00','00','00','00','00','00','00',
        '00','00','00','00','00','00','00','00',
        '00','00','00','00','00','00','00','00',
        '00','00','00','00','00','00','00','00',
    ],
    light_rect: [],
};

const kdevice = (state = initialState, action:any) => {
    switch (action.type) {
        case Type.connectDevice:
        return {
            ...state,
            connect: action?.connect,
            device: action.device
        };
        case Type.getDeviceMessage:
        return {
            ...state,
            count: state.connect,
        };
        case Type.setTyfun:
            console.log(action, 'action @@')
        return {
            ...state,
            setting: JSON.parse(JSON.stringify(action.setting)||JSON.stringify(state.setting)),
        };
        case Type.install:
            return {
                ...state,
                ...action?.deviceSettting
            };
        case Type.setRectKey:
            return {
                ...state,
                key_Rect: JSON.parse(JSON.stringify(action?.key_Rect))||[]
            }
        case Type.installLightRect:
            return {
                ...state,
                light_rect: JSON.parse(JSON.stringify(action?.light_rect)||JSON.stringify(state.light_rect)),
            }
        case Type.updateLightRect:
            return {
                ...state,
                light_rect: JSON.parse(JSON.stringify(action?.light_rect)||JSON.stringify(state.light_rect)),
            }
        default:
            return state;
    }   
};


export default kdevice;
