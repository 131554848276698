type defaultkeyType = {
    vendorId: number,
    productId: number,
    reqLen: number
};
const message = [
    {
        vendorId: 1389,
        productId: 49271, //Wireless Receiver (Z108 无线)
        reqLen: 32,
    },
    {
        vendorId: 1389,
        productId: 49288,//Gaming Keyboard
        reqLen: 64,
    },
    {
        vendorId: 10473,
        productId: 12410, //Z108 有线
        reqLen: 64,
    }
];

export default {
    message,
    getByName(arr:string[] = ["vendorId", "productId"]) {
        return message.map((item:defaultkeyType) => {
            const param = {};
            arr.forEach((str:string) => {
                param[str] = item[str] ||'';
            })
            return param;

        })
    },
    getRequstDataLen(productId) {
        return message.find((item) => item?.productId === productId)?.reqLen || 64;
    }
}