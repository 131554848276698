import React, { useEffect, useState } from 'react';
import { Row, Col, Dropdown, message, Spin } from 'antd';
import Left from '../pages/settting/left.tsx';
import { DisconnectOutlined, LinkOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getDevices,
  reqcount,
  install,
  tySendReport,
  saveRectKey,
  updateKeyRect,
  setTyfun,
  testConnect,
  restAll,
  removeDevices,
  checkConnect,
  readPoor
} from '../store/kdevice/imp.ts';
import { setTySetting, installSetting, chooseProfile } from '../store/setting/imp.ts';


export default function RootLayout({
  children,
  locale,
  t,
  changeLocale,
}: Readonly<{
  children: any;
  locale: string,
  t: Function,
  changeLocale: Function
}>) {
  const [theme, setTheme] = useState<string>('pink-theme');
  const dispatch = useDispatch();
  const {connect, productId} = useSelector((state:any) => state.kdevice);
  const ndevice = useSelector((state:any) => state.kdevice.device);
  const {settings, checkedProFile} = useSelector((state:any) => state.settingReducer);
  const [visible, setVisible] = useState<boolean>(false);

  const setinit = () => {
    setTimeout(() => {
      if (reqcount === 0) {
        // 初始化left 数据 checked index === 0；
        const {type, deviceSettting}:any = install();
        // 根据产品id处理数据
        if (deviceSettting && deviceSettting?.productId) {
          const {productId, setting, key_Rect, default_key_Rect, light_rect} = deviceSettting;
          console.log(`setting ${JSON.stringify(setting)}`)
          
          let result = JSON.parse(localStorage.getItem(`${productId}`)||'[]');
          const frisProFile =
            {
              key: '00000000001',
              name: 'profile1',
              tysetting: setting,
              hongsetting: ``,
              key_Rect,
              default_key_Rect,
              light_rect
            };

          if (!result || !result?.length) {
            // 缓存中没有默认参数
            result = [{...frisProFile}];
            // setChecked('00000000001');
            dispatch(chooseProfile('00000000001'))
          } else {
            // 缓存中有默认参数
            // setChecked(result[0].key);
            dispatch(chooseProfile(result[0].key));
          }
          dispatch(installSetting(productId, {
            hongsetting: ``,
            key_Rect: default_key_Rect,
            default_key_Rect,
            // tysetting: setting,
          }, result));
        }
        dispatch({type, deviceSettting});
        
      } else {
        setinit();
      }
    }, 200);
  }

  // 设备连接
  const handleClick = async () => {
    // 使用 dispatch 触发一个 Action
    const result = await getDevices();
    if (result.connect && result.device) {
      dispatch(result);
      setinit();
    } else {
      message.error(t(`connectfail`))
    }
    
  };

  const setTyInit = (data:string[]) => {
    setTimeout(() => {
      if (reqcount === 0) {
        dispatch(setTyfun(data)); // 存储通用信息到当前使用的通用模块
        dispatch(setTySetting(data)); // 存储通用信息到prodile store
      } else {
        setTyInit(data);
      }
    }, 200);
  }
  const setKeyRectInit = (arr:any) => {
    setTimeout(() => {
      if (reqcount === 0) {
        dispatch(updateKeyRect(arr))
      } else {
        setKeyRectInit(arr);
      }
    }, 200);
  }
  const handClick = async (nkey:string) => {
    const tag = await testConnect();
    if (!tag?.connect) {
        dispatch(tag);
        return;
    }
    const beforItem = checkedProFile ? settings.find(({key}:any) => key === checkedProFile):"";
    const nowItem = settings.find(({key}:any) => key === nkey);

    dispatch(chooseProfile(nkey));

    if (!beforItem || JSON.stringify(beforItem.tysetting) !== JSON.stringify(nowItem.tysetting)
      ) {
      tySendReport(nowItem.tysetting);
      setTyInit(nowItem.tysetting);
    }

    if (!beforItem ||
      JSON.stringify(beforItem.key_Rect) !== JSON.stringify(nowItem.key_Rect)) {
      saveRectKey(nowItem.key_Rect);
      setKeyRectInit(nowItem.key_Rect);
    }

  }
  
  // 重置按钮
  const restHandClick = async () => {
    const tag = await testConnect();
    if (!tag?.connect) {
        dispatch(tag);
        return;
    }
    restAll();
    
    setinit();
  }
  const lang:MenuProps['items'] =[
    {
      key: 'en',
      label: (
        <span style={{color: '#333'}} onClick={()=>changeLocale('en')}>
          {t('en')||''}
        </span>
      ),
    },
    {
      key: 'zh',
      label: (
        <span style={{color: '#333'}} onClick={()=>changeLocale('zh')}>
          {t('zh')||''}
        </span>
      ),
    },
    {
      key: 'fr',
      label: (
        <span style={{color: '#333'}} onClick={()=>changeLocale('fr')}>
          {t('fr')||''}
        </span>
      ),
    },
    {
      key: 'kr',
      label: (
        <span style={{color: '#333'}} onClick={()=>changeLocale('kr')}>
          {t('kr')||''}
        </span>
      ),
    }
  ];
  const themes:MenuProps['items'] =[
    {
      key: 'line-theme',
      label: (
        <span style={{color: '#333'}} onClick={()=>setTheme('line-theme')}>
          {t('line-theme')||''}
        </span>
      ),
    },
    {
      key: 'black-theme',
      label: (
        <span style={{color: '#333'}} onClick={()=>setTheme('black-theme')}>
          {t('black-theme')||''}
        </span>
      ),
    },
    {
      key: 'pink-theme',
      label: (
        <span style={{color: '#333'}} onClick={()=>setTheme('pink-theme')}>
          {t('pink-theme')||''}
        </span>
      ),
    }
  ];

  useEffect(():any => { 
    if (localStorage.getItem('key-board-locale-lang') &&
      ['zh', 'en', 'fr', 'kr'].includes(localStorage.getItem('key-board-locale-lang') as string)) {
      changeLocale(localStorage.getItem('key-board-locale-lang')||'zh');
    }
    if (localStorage?.getItem('key-board-theme-color') &&
      ['line-theme', 'black-theme', 'pink-theme'].includes(localStorage.getItem('key-board-theme-color') as string))
      setTheme(localStorage.getItem('key-board-theme-color')||'pink-theme');
  }, []);

  useEffect(() => {
    function removedevice({ device }:any) {
      console.log(device.productId, 'device', ndevice?.productId);
      if (ndevice?.productId === device?.productId) dispatch(removeDevices());
    }
    (navigator as any)?.hid?.removeEventListener("disconnect", removedevice);
    (navigator as any)?.hid?.addEventListener("disconnect", removedevice);

  }, [ndevice]);

  useEffect(() => {
    localStorage.setItem('key-board-locale-lang', locale?locale:"zh");
  }, [locale]);
  useEffect(() => {
    localStorage.setItem('key-board-theme-color', theme?theme:'pink-theme');
  }, [theme]);
  
  // useEffect(() => {
  //   if (productId !== 49271) return;
  //   let timefun;
  //   let poortimefun;
  //   if (connect) {
  //     checkConnect();
  //     if (timefun) clearInterval(timefun);
  //     timefun = setInterval(() => {
  //       checkConnect();
  //     }, 5000);
  //     poortimefun = setInterval(() => {
  //       readPoor();
  //     }, 12000);
  //   } else {
  //     if (timefun) clearInterval(timefun);
  //     if (poortimefun) clearInterval(poortimefun);
  //   }
  // }, [connect, productId]);
  return (
    <Spin indicator={undefined} tip="" spinning={visible}>
      <Row className={`${theme}`}>
        <Col className='header'>
          <div className='logo'>
            <img alt='1' src = {`./assert/logo.png`} />
          </div>
          <div className={`menu ${connect ? 'connect': 'disconnect'} `} onClick={handleClick}>
            {connect ?
              <LinkOutlined size={50} width='50vw' />:
              <DisconnectOutlined/>
            }
            <span className='text'>{connect ? t(`hasconnect`):t(`pleaseConnect`)}</span>
          </div>
          <div className='setting'>
            <span className='item'>
              <Dropdown 
                menu={{'items':lang}}
                placement="bottom">
                <span style={{color: '#333'}}>
                  {t(locale)||''}
                </span>
              </Dropdown>
            </span>
            <span className='item'>
              <Dropdown
                menu={{'items':themes}}
                placement="bottom">
                <span style={{color: '#333'}}>
                  {t(theme)||''}
                </span>
              </Dropdown>
            </span>
          </div> 
        </Col>
        <Col className='main-box'>
          <div style={{width: '100vw', height: '92vh'}}>
            <div className='silder'>
              <Left t={t}
                setChecked={handClick}
                restHandClick={restHandClick}/>
            </div>
            <div className='content'>
              {
                connect ?
                  children:
                  <div className='connectTip'>
                    <ExclamationCircleOutlined />
                    {t(`connectTip`)}
                  </div>
              }
            </div>
          </div>
        </Col>
      </Row>
    </Spin>
  );
}
