import React, { useEffect, useState } from 'react';
import { keyButton } from '../../common/component/btns/btns.tsx';
import { keysTypes, keyTypeNum } from '../../common/text.ts'
import { useSelector, useDispatch } from 'react-redux';
import { setCheckedBtn } from '../../store/setting/imp.ts'
import { tenTo16 } from '../../common/global.ts';

// const keytext = keys98;

const KeyBoard = (props:any) => {
    const dispatch = useDispatch();
    const [keytext, setKeytext] = useState({
        width: 36,
        height: 12.4,
        keys: []
    });
    const [movein, setMovein] = useState<string>('');
    const [scal, setScal] = useState<number>(25);
    const [ob, setObj] = useState<{x:number, y:number, s:number}>({x: 200, y: 100, s: 1});
    const [pressKey, setPressKey] = useState<{
        keyCode: number,
        code: string
    }>({
        keyCode: 0,
        code: ''
    });
    const {key_Rect,
        default_key_Rect,
        key_rect_size,
        light_rect,
        setting,
        productId}:any = useSelector((state:any) => state.kdevice);
    const {checkedKey, checkedProFile, settings} = useSelector((state:any) => state.settingReducer);
    const [keyChangData, setKeyChangData] = useState({});
    const [colors, setColors] = useState({});

    const handClick = (code:string) => {
        dispatch(setCheckedBtn(code));
        if(props.changeDefaultColor) props.changeDefaultColor(code);
    }

    const getRelative = () => {
        if (productId) keyTypeNum.set(`${productId}`);
        const codeValue:any = keyTypeNum.getCodeValue();

        let relData:any = {};
        for (const key in codeValue) {
            const arr = [...codeValue[key]][Symbol.iterator]();
            const type:string = arr.next().value; 
            let neykey:string = `${type}${arr.next().value||""}${arr.next().value||""}`.toLocaleLowerCase()
            relData[neykey] = key;
                     
            // console.log('key', key, arr, neykey)
        }
        return relData;
    }

    const getKeyColorRelation = (relData:any, dkRect:any, lightRect:any) => {
        let relation1:any = {};
        for (let i = 0; i < Math.ceil(dkRect.length / 3); i++) {
            const key:string = `${tenTo16(dkRect[i*3])}${tenTo16(dkRect[i*3+1])}${tenTo16(dkRect[i*3+2])}`;
            const itemKey:string = relData[key];
            if (itemKey) relation1[itemKey] = `rgba(${lightRect[i*3]}, ${lightRect[i*3+1]}, ${lightRect[i*3+2]}, 1)`;
        }

        return relation1;
    }

    useEffect(() => {
        if (!key_Rect 
            || !default_key_Rect 
            || default_key_Rect?.length < key_rect_size 
            || key_Rect?.length < key_rect_size) return;
        
        const relData = getRelative();
        let param:any = {};
        
        // key 键值处理
        for (let i = 0; i < Math.ceil(key_Rect.length/3); i++) {
            const dcodenum = `${tenTo16(default_key_Rect[i*3])}${tenTo16(default_key_Rect[i*3+1])}${tenTo16(default_key_Rect[i*3+2])}`;
            const codenum = `${tenTo16(key_Rect[i*3])}${tenTo16(key_Rect[i*3+1])}${tenTo16(key_Rect[i*3+2])}`;
            const dkeyCode = relData[dcodenum];
            const keyCode = relData[codenum];
            if (keyCode) {
                param[dkeyCode] = keyCode;
            } else {
                const fnstr:string = keyTypeNum?.k?.['fn'];
                if (tenTo16(key_Rect[i*3]).toLocaleLowerCase() === fnstr.toLocaleLowerCase()) {
                    let narr = [parseInt(tenTo16(key_Rect[i*3+1])[2], 16), parseInt(tenTo16(key_Rect[i*3+1])[3], 16)];
                    let count = 8;
                    let skeyCode = "";
                    let defkeycode:string = "";
                    // 组合键
                    while (count >= 1) {
                        if (narr[0] / count >= 1) {
                            if (skeyCode) skeyCode += " ";
                            defkeycode = relData[`${fnstr}${tenTo16(count*16)}`];
                            skeyCode += props.t(defkeycode) ? props.t(defkeycode):defkeycode;
                            narr[0] = narr[0] % count;
                        }
                        count /= 2; 
                    }
                    count = 8;
                    while (count >= 1) {
                        if (skeyCode) skeyCode += " ";
                        if (narr[1] / count >= 1) {
                            defkeycode = relData[`${fnstr}${tenTo16(count)}`];
                            skeyCode += props.t(defkeycode) ? props.t(defkeycode):defkeycode;
                            narr[1] = narr[1] % count;
                        }
                        count /= 2; 
                    }
                    if (`${tenTo16(key_Rect[i*3+2])}` !== '0x00') {
                        if (skeyCode) skeyCode += " ";
                        defkeycode = relData[`${keyTypeNum?.k?.['kbbase']}0x00${tenTo16(key_Rect[i*3+2])}`];
                        skeyCode += props.t(defkeycode) ? props.t(defkeycode):defkeycode;
                    }
                    param[dkeyCode] = skeyCode;
                }
                console.log(tenTo16(key_Rect[i*3]))
                if (tenTo16(key_Rect[i*3]).toLocaleLowerCase() === keyTypeNum?.k?.['hong1'].toLocaleLowerCase()||
                    tenTo16(key_Rect[i*3]).toLocaleLowerCase() === keyTypeNum?.k?.['hong2'].toLocaleLowerCase()) {
                    const settingItem = settings.find(({key}:any) => key === checkedProFile);
                    const hongname = settingItem.hongsetting;
                    // alert(hongname)
                    if (hongname) {
                        let arr = JSON.parse(localStorage.getItem(hongname)||'[]'); 
                        let index = parseInt(key_Rect[i*3+1]);
                        // console.log(`${index} ${arr[index-1]?.name}`)
                        param[dkeyCode] = arr[index]?.name;
                    }
                }  
            }
            // console.log('dkeyCode', dcodenum, dkeyCode, codenum,keyCode)
        }

        setKeyChangData(param);
        console.log(relData,"setKeyChangData", param)

    }, [key_Rect, default_key_Rect, key_rect_size, checkedProFile]);

    useEffect(() => {
        if (!light_rect 
            || !default_key_Rect 
            || default_key_Rect?.length < key_rect_size 
            || light_rect?.length < key_rect_size) return;
        if (setting && setting[1] === '0x11') {
            const relData = getRelative();
        
            // 灯光关系处理
            const colorrelation = getKeyColorRelation(relData, default_key_Rect, light_rect);
            setColors(colorrelation);
        } else {
            setColors({});
        }
    }, [default_key_Rect, checkedProFile, key_rect_size, light_rect, setting]);

    useEffect(() => {
        if (!productId) return;
        const _keysn = keysTypes?.[`${productId}`];
        if (!_keysn) return;
        

        const s = Math.round(window?.innerHeight * 0.31 / _keysn.height);
        const y = s * _keysn.height;
        const x = s * _keysn.width;
        setObj({x,y, s});
        setScal(s);
        setKeytext({..._keysn});
        // function keydown(e:any) {
        //     debounce('setkeycode', () => {
        //         console.log(e.keyCode,'------------e.keyCode', e.code),
        //         setPressKey({keyCode: e.keyCode, code: e.code})
        //     }, 50)
        // }

        // document.addEventListener('keydown',keydown)
        // return () => {
        //     document.removeEventListener('keydown', keydown)
        // }
    }, [productId])

    return <div className='key-board-top'
        style={{
            height: '35vh',
            width: '100%',
            textAlign: 'center',
            fontSize: `${Math.floor(ob.s/3)}px`
        }}
        onKeyDown={(e:any)=> {
            // console.log(e.keyCode,'------------e.keyCode', e.code),
            setPressKey({keyCode: e.keyCode, code: e.code})
        }}
        >
        <div style={{
            width: ob.x,
            height: ob.y, margin: `0 auto`,
            position: 'relative'}}
            className='key-board-body'>
            {
                keytext && keytext?.keys && keytext.keys?.length ?
                    keytext.keys?.map((item:any) => {
                        return keyButton(
                            item,
                            scal,
                            pressKey,
                            keyChangData,
                            handClick,
                            checkedKey,
                            movein,
                            setMovein,
                            props.t,
                            colors
                        );
                    }):[]
            }
        </div>
    </div>
}

KeyBoard.displayName = 'KeyBoard';
export default KeyBoard;