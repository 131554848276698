// 功能信息格式（预留64字节）
export const funFormat = [
    /** 配置索引 0-4 */
    'profile_index',
    /** 灯效模式 0-17 */
    'light_mode',
    /** 灯效亮度 0-99 */
    'light_level',
    /** 灯效速度 0-4 */
    'light_speed',
    /** 灯效方向 0-正向 1-反向 */
    'light_trend',
    /** 灯效多彩或单色 0-单色 1-RGB */
    'light_RGB',
    /** 单色值_R 0-255 */
    'r_main_value',
    /** 单色值_G 0-255 */
    'g_main_value',
    /** 单色值_B 0-255 */
    'b_main_value',
    /** 颜色索引 0-8：0红 1橙 2黄 3绿 4青 5蓝 6紫 7白 8多彩 */
    'light_colour',
    /** 预留 */
    'reserved',
    /** 预留 */
    'reserved',
    /** 锁WIN标志 0-不锁 1-锁WIN */
    'win_flag',
    /** 六键无冲标志 0-全键无冲 1-六键无冲 */
    'kb_6key_mode',
    /** 方向键与WASD键切换标志 0-不切换 1-切换 */
    'f_wasd',
    /** 2.4G关闭背光时间_L 0-255 单位:秒 */
    'D24G_OFF_LED_TIME_L',
    /** 2.4G关闭背光时间_H 0-255 单位:秒 */
    'D24G_OFF_LED_TIME_H',
    /** 2.4G进入睡眠时间_L 0-255 单位:秒 */
    'D24G_SLEEP_TIME_L',
    /** 2.4G进入睡眠时间_H 0-255 单位:秒 */
    'D24G_SLEEP_TIME_H',
    /** BLE关闭背光时间_L 0-255 单位:秒 */
    'BLE_OFF_LED_TIME_L',
    /** BLE关闭背光时间_H 0-255 单位:秒 */
    'BLE_OFF_LED_TIME_H',
    /** BLE进入睡眠时间_L 0-255 单位:秒 */
    'BLE_SLEEP_TIME_L',
    /** BLE进入睡眠时间_H 0-255 单位:秒 */
    'BLE_SLEEP_TIME_H',
    /** USB关闭背光时间_L 0-255 单位:秒 */
    'USB_OFF_LED_TIME_L',
    /** USB关闭背光时间_H 0-255 单位:秒 */
    'USB_OFF_LED_TIME_H'
];
/** 灯光效果 */
// const light_modes = [
//     /** 波浪 */
//     0,
//     /** 彩云 */,
//     1,
//     /** 霓虹 */
//     2,
//     /** 回旋 */
//     3,
//     /** 百花 */
//     4,
//     /** 飞翔 */
//     5,
//     /** 下雨 */
//     6,
//     /** 穿越 */
//     7,
//     /** 光谱 */
//     8,
//     /** 呼吸 */
//     9,
//     /** 常亮 */
//     10,
//     /** 涟漪 */
//     11,
//     /** 敲击 */
//     12,
//     /** 单点 */
//     13,
//     /** 扩散 */
//     14,
//     /** 扫描 */
//     15,
//     /** 渐变 */
//     16,
//     /** 自定义 */
//     17,
//     /** 关闭 */
//     18,
// ]

/** 设备信息格式 */
export const deviceFormat = [
    'VALID_DATA_LENGTH_L',// 数据有效位L	0xaa
    'VALID_DATA_LENGTH_H',//数据有效位H	0xbb
    'PRODUCT_ID_L',//产品ID_L	
    'PRODUCT_ID_H',//产品ID_H	
    'KET_RECT_SIZE',//按键矩阵大小	单位：3字节
    'HONG_SIZE',//宏空间大小	单位：256字节
]


/** 宏格式 */
const ACTION = [
    'ACTION_DELAY_TIME_L',//宏动作延时时间L
    'ACTION_DELAY_TIME_H',//宏动作延时时间H
    'KEY_CODE', //按键值
    'KEY_TYPE' //宏动作按键类型
];
export const hongAction = [
    'ACTION_TOTOL_L',// 宏1动作数量L
    'ACTION_TOTOL_H',// 宏1动作数量H
    'RESERVED',// 保留	
    'RESERVED',// 保留
    ...ACTION,
];

export const hongItems = [
    'USE_TAG_L', //是否使用标志位L
    'USE_TAG_H', //是否使用标志位H
    'USE_LENGTH_L', //使用长度L
    'USE_LENGTH_H', //使用长度H
    'HONG_TOTOL_L', // 宏数量L
    'HONG_TOTOL_H', //宏数量H
    'RESERVED',// 保留	
    'RESERVED',// 保留
];


export const option = {
    "down": "1",
    "up": "0"
}

