import Type from './type.ts'
import {Command, CommandWay, getDataWay} from '../../common/request/txFormat.ts';
import {calcTime, tenTo16} from '../../common/global.ts';
import HidRequest from '../../common/request/hidRequest.ts';
import products from '../../common/data/product.ts'

export let ndevice:any = null;
export let reqcount:number = 0;
// export let reqcount:number = 0;
// export let tyreqcount:number = 0; // 记录通用模块发送未响应请求次数  0x85
let hid:HidRequest;

let deviceSettting = {
    productName: "productName",
    productId: "",
    _productId: 1,
    vendorId: "",
    proFileId: "",
    valid_data_length: 64,
    key_rect_size: 256,
    hong_space_size: 2048,
    setting: [], // 通用信息
    default_key_Rect: [],
    key_Rect: [],
    hong_data: [],
    light_rect: [],
}

export const testConnect = async () => {
    const bol:boolean = hid && hid?.canConnect ? await hid?.canConnect() : false;
    return {
        type: Type.connectDevice,
        connect: bol,
        device: null,
    }
}

export const restAll = () => {
    const defaultData = CommandWay(Command?.get('restKeyBoard'), [], ["00", "00"], hid?.getReqLen());
    hid.request(defaultData);
}

export const tySendReport = (data = []) => {
    const datalen = hid?.getReqLen() - 7;
    const sendCount = Math.ceil(64 / datalen);

    for (let i = 0; i < sendCount; i++) {
        let ndata:string[] = [];;
        let j = i*datalen;
        while(j >= i*datalen && j < (i+1)*datalen) {
            ndata.push(tenTo16(data[j]));
            j++;
        }
        
        const defaultData = CommandWay(Command?.get('setData'), ndata, calcTime(i*datalen), hid?.getReqLen());
        hid.request(defaultData);
    }
}


export const saveRectKey = async(data:number[] = []) => {
    const datalen = hid?.getReqLen() - 7;
    const sendCount = Math.ceil(data?.length / datalen);

    for (let i = 0; i < sendCount; i++) {
        let ndata:string[] = [];;
        let j = i*datalen;
        while(j >= i*datalen && j < (i+1)*datalen) {
            ndata.push(tenTo16(data[j]));
            j++;
        }
        
        const defaultData = CommandWay(Command?.get('setkeyMessage'), ndata, calcTime(i*datalen), hid?.getReqLen());
        hid.request(defaultData);
    }   
}

// 读取设备信息
const readDeviceMessage = async() => { 
    const newData = CommandWay(Command?.get('getDeviceMessage'), [], ["00", "00"], hid?.getReqLen());
    await hid.request(newData);
    reqcount = 1;
}

// 读取通用信息
const readTyMessage = () => {  
    const newData = CommandWay(Command?.get('getdData'), [], ["00", "00"], hid?.getReqLen());
    hid.request(newData);
    reqcount = 1;
}

// 读取默认按键信息
const readDefaultKeyMessage = () => {
    const datalen = hid?.getReqLen() - 7;
    const sendCount = Math.ceil(deviceSettting?.key_rect_size / datalen);

    for (let i = 0; i < sendCount; i++) {
        // 读取 第 i个 默认按键信息
        const defaultData = CommandWay(Command?.get('getKeyboard'), [], calcTime(i*datalen), hid?.getReqLen());
        hid.request(defaultData);
        reqcount = 1;
    }

    for (let i = 0; i < sendCount; i++) {
        // 读取 第 i个 按键信息
        const defaultData = CommandWay(Command?.get('getkeyMessage'), [], calcTime(i*datalen), hid?.getReqLen());
        hid.request(defaultData);
        reqcount = 1;
    }
}

// 检查2.4G连接
export const checkConnect = () => {
    const defaultData = CommandWay(Command?.get('checkConnect'), [], ["00", "00"], hid?.getReqLen());
    hid?.request(defaultData);
    reqcount = 1;
}

// 读取按键信息
// const readKeyMessage = async () => {
//     try {
//         console.log('2')
//         if (!ndevice) {
//             alert('请连接设备');
//             throw "Device is null";
//         } else if (ndevice && !ndevice?.opened) {
//             await ndevice.open();
//         } else if (!ndevice?.opened) {
//             throw "Device not opened";
//         }
        
//         const newData = CommandWay[Command?.getkeyMessage]([]);
//         const buffer:Uint8Array = new Uint8Array(63);

//          newData?.forEach((ele:string|number, i:number) => {
//             buffer[i] = parseInt(ele+"", 16);
//         })
//         await ndevice.sendReport(6, buffer);
//     } catch (e) {
//         console.log('报错', e)
//     } 
// }

// 读取自定义灯光信息
export const readLightRect = () => {
    const datalen = hid?.getReqLen() - 7;
    const sendCount = Math.ceil(deviceSettting?.key_rect_size / datalen);

    for (let i = 0; i < sendCount; i++) {
        const defaultData = CommandWay(Command?.get('getlightMessage'), [], calcTime(i*datalen), hid?.getReqLen());
        hid.request(defaultData);
        reqcount = 1;
    }
}

// 设置自定义灯光信息
export const setLightRect = (data:number[] = []) => {
    const datalen = hid?.getReqLen() - 7;
    const sendCount = Math.ceil(data?.length / datalen);

    for (let i = 0; i < sendCount; i++) {
        let ndata:string[] = [];;
        let j = i*datalen;
        while(j >= i*datalen && j < (i+1)*datalen) {
            ndata.push(tenTo16(data[j]));
            j++;
        }
        
        const defaultData = CommandWay(Command?.get('setlightMessage'), ndata, calcTime(i*datalen), hid?.getReqLen());
        hid.request(defaultData);
        reqcount = 1;
    }
}

// 读取宏信息

// 读取电池状态(电量、充电)
export const readPoor = async () => {
    const defaultData = CommandWay(Command?.get('getPoorNum'), [], ["00", "00"], hid?.getReqLen());
    hid.request(defaultData); 
}

// 读取灯光矩阵信息

// 
//读取当前PROFILE
// const readProFileId = async () => {
//     reqcount++;
//     try {
//         console.log('2')
//         if (!ndevice) {
//             alert('请连接设备');
//             throw "Device is null";
//         } else if (ndevice && !ndevice?.opened) {
//             await ndevice.open();
//         } else if (!ndevice?.opened) {
//             throw "Device not opened";
//         }
        
//         const newData = CommandWay[Command?.getProFile]([]);
//         const buffer:Uint8Array = new Uint8Array(63);

//          newData?.forEach((ele:string|number, i:number) => {
//             buffer[i] = parseInt(ele+"", 16);
//         })
//         await ndevice.sendReport(6, buffer);
//     } catch (e) {
//         console.log('报错', e)
//     } 
// }
//文件解析 = 

export const getDevices = async () => {
    if (!(navigator as any).hid) {
        console.log('当前环境不支持navigator.hid', navigator);
        alert(`当前环境不支持navigator.hid`);
        return {
            type: Type.connectDevice,
            connect: false,
            device: null,
        };
    }
    try {
        hid = new HidRequest(products.getByName(['productId', "vendorId"]));
        ndevice = await hid.getDevice(); // 设备连接

        if (!ndevice.opened) {
            await ndevice.open(); // 打开设备
            console.log(`Open device: \n${ndevice.productName}\nPID-${ndevice.productId} VID-${ndevice.vendorId}`);
            Command.set(`${ndevice.productId}`);
            hid.setReqLen(products.getRequstDataLen(ndevice.productId));
            readDeviceMessage();

            ndevice.addEventListener("inputreport", (event:any) => {
                
                const { data, device, reportId } = event;
                if (reportId !== 6 && reportId !== 7) return;

                deviceSettting.productName = device?.productName;
                deviceSettting.productId = device?.productId;
                deviceSettting.vendorId = device?.vendorId;

                console.log('--------------------------------------------');
                // 返回数据初始化
                let result:number[] = [];
                let len = data?.byteLength;
                let i = 0;
                while (i < len) {
                    result[i] = data.getUint8(i);
                    i++;
                }
                // 处理初始化请求 
                const optionCode = tenTo16(result[0]).toLocaleLowerCase();

                hid.callback(result[0]);
                reqcount = hid.requesting ? 1 : 0;

                const resp = getDataWay(optionCode, result, deviceSettting, hid?.getReqLen());
                if (resp?.status) {
                    console.log('resp?.data', resp?.data)
                    deviceSettting = {
                        ...deviceSettting,
                        ...resp?.data,
                    }

                    if (`${optionCode}` === Command.get('getDeviceMessage')||
                        `${optionCode}` === Command.get('restKeyBoard')) {
                        // 初始化基本配置
                        readTyMessage();

                        // 初始化键盘
                        readDefaultKeyMessage();
                        
                        // 初始化宏                     

                        // 获取电量信息
                        // readPoor();

                        // 获取profileid
                        // readProFileId();
                    
                    }
                }
            });
        }
        if (ndevice) {
            return {
                type: Type.connectDevice,
                connect: true,
                device: ndevice,
            };
        } else {
            return {
                type: Type.connectDevice,
                connect: false,
                device: null,
            }
        }
    } catch (error) {
        return {
            type: Type.connectDevice,
            connect: false,
            device: null,
        }
    }
}

export const removeDevices = () => {
    return {
        type: Type.connectDevice,
        connect: false,
        device: null,
    }

}



// 初始化获取默认键盘
// export const getKeysMessage = () => {
//     // reqcount++;
//     try {
//          const buffer = new Uint8Array(63);

//         const newData = CommandWay(Command?.getKeyboard, []);
//          console.log(newData, 'newData')
//          newData.forEach((ele:string, i:number) => {
//             buffer[i] = parseInt(ele+"", 16);
//         });
//         console.log(buffer, '-----------------------key模块')
//         ndevice.sendReport(parseInt(tyReportId, 16), buffer);
//     } catch (e) {
//         console.log('报错', e)
//     }
// }


// 更新呢 通用信息
export const setTyfun = (setting:string[]) => {
    return {
        type: Type.setTyfun,
        setting
    }
}

// 更新键盘信息 
export const updateKeyRect = (arr:any) => {
    return {
        type: Type.setRectKey,
        key_Rect: arr
    }
}

export const install = () => {
    // 设备信息获取完成后数据初始化

    console.log('deviceSettting', deviceSettting)

    return {
        type: Type.install,
        deviceSettting
    }
}

// 存储自定义键盘灯光
export const installLightRect = () => {
    // 设备信息获取完成后数据初始化
    return {
        type: Type.installLightRect,
        light_rect: deviceSettting.light_rect
    }
}
// 更新定义键盘灯光
export const updateLightRect = (light_rect:any) => {
    // 设备信息获取完成后数据初始化
    return {
        type: Type.updateLightRect,
        light_rect
    }
}

