import React, {useEffect, useState, useRef} from 'react';
import {Row, Col } from 'antd'
// import ReactColor from 'react-color/lib';
import Wheel from '@uiw/react-color-wheel';
import ShadeSlider from '@uiw/react-color-shade-slider';
import { hsvaToHex, hsvaToRgba, hexToHsva, rgbaToHsva, rgbaToHex} from '@uiw/color-convert';
import './style.less';
import {clacColor} from '../../global.ts'

// const CirclePicker = ReactColor.CirclePicker;

const colors:any = [
    '#FF0000',
    '#FF6400',
    '#FFFF00',
    '#00FF00',
    '#00FFFF',
    '#0000FF',
    '#FF00FF',
    '#FFFFFF',
    '#000000'
];

const LightSetting = ({t, setColor, color, data}:any) => {
    const [hsva, setHsva] = useState({ 'h': 214, 's': 43, 'v': 90, 'a': 1 });
    const [wheelhsva, setWheelHsva] = useState({"h":0,"s":0,"v":100,"a":1});
    const [color1, setColor1] = useState('#ff0000');
    const [color2, setColor2] = useState({r: 255, g: 0, b: 0, a:1 });

    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);

    const handClick = (color:any) => {
        if (setColor && color && Object.keys(color).length>0) {
            setColor({...hsvaToRgba(color)});
        }    
    }

    useEffect(() => {
        const {b_main_value, g_main_value, r_main_value, light_mode} = data;
        if (light_mode !== 17) {
            if (data && Object.keys(data)?.length > 0) {
                const trgba = {
                    'r': r_main_value,
                    'g': g_main_value,
                    'b': b_main_value,
                    'a': 1,
                };
                console.log('trgba', trgba,data)
                setHsva(rgbaToHsva(trgba));
                setColor1(rgbaToHex(trgba));
                setColor2(trgba);
            } else {
                setHsva({"h":255,"s":0,"v":100,"a":1});
            }
        } else {
            if (color && Object.keys(color)?.length > 0) {
                setHsva(rgbaToHsva(color));
                setColor1(rgbaToHex(color));
                setColor2(color);
            } else setHsva({"h":255,"s":0,"v":100,"a":1});
        }
        
    },[data, color]);

    const changeColor = (val:string) => {
        if (!val) return;
        const reg = new RegExp(/^[#]{1}[0-9a-fA-F]{0,6}$/);
        if (reg.test(val)) {
            setColor1(val||'0');
        } 
    }

    const rgbachangeColor = (_c:string,  val:string) => {
        if (!_c) return;
        if (!val) {
            setColor2({...color2, [_c]: 0});
        } else {
            const reg:RegExp = new RegExp(/^[0-9]{1,3}$/);
            if (reg.test(val)) {
                setColor2({...color2, [_c]: parseInt(val) > 255 ? `255` : `${parseInt(val)}`});
            }
        }
    }

    return <div className = 'linebox'>
       <Row style={{height: '35vh', overflowY: 'scroll'}} gutter={[16,16]}>
            <Col span={24}>
                <span style={{paddingBottom: '2vh'}}>{`${t(`color`)}`}</span>
            </Col>
            <Col span={9}>
                {/* <div className='colordiv' style={{
                    background: hsvaToHex(hsva) }}>{`${hsvaToHex(hsva)}`}</div> */}
                <input className='colordiv'
                    style={{
                        background: hsvaToHex(hsva),
                        border:`1px ${hsvaToHex(hsva)} solid`
                    }}
                    ref={inputRef1}
                    value={color1}
                    onChange={(e:any) => changeColor(e?.target?.value||"")}
                    onBlur={() => handClick({...hsva, ...hexToHsva(clacColor(color1||"000"))})}
                    onKeyDown={(e) => {
                        if (e?.code === 'Enter') {
                            handClick({...hsva, ...hexToHsva(clacColor(color1||"000"))});
                            if (inputRef1?.current) (inputRef1?.current as any)?.blur();
                        }
                    }}
                />  
            </Col>
            <Col span={5} className='rgbbox'>
                <span className='text'>R: </span>
                {/* <span className='value'>{`${hsva?.h||hsva?.h===0 ? hsvaToRgba(hsva)?.r:''}`}</span> */}
                <input className='value'
                    ref={inputRef2}
                    style={{border:0}}
                    value={color2.r}
                    onChange={(e:any) => rgbachangeColor('r', e?.target?.value||"")}
                    onBlur={() => handClick({...hsva, ...rgbaToHsva(color2)})}
                    onKeyDown={(e) => {
                        if (e?.code === 'Enter') {
                            handClick({...hsva, ...rgbaToHsva(color2)});
                            if (inputRef2.current) (inputRef2.current as any)?.blur();
                        }
                    }}
                />  
            </Col>
            <Col span={5} className='rgbbox'>
                <span className='text'>G: </span>
                {/* <span className='value'>{`${hsva?.h||hsva?.h===0 ? hsvaToRgba(hsva)?.g:''}`}</span> */}
                <input className='value'
                    ref={inputRef3}
                    style={{border:0}}
                    value={color2.g}
                    onChange={(e:any) => rgbachangeColor('g', e?.target?.value||"")}
                    onBlur={() => handClick({...hsva, ...rgbaToHsva(color2)})}
                    onKeyDown={(e) => {
                        if (e?.code === 'Enter') {
                            handClick({...hsva, ...rgbaToHsva(color2)});
                            if (inputRef3.current) (inputRef3.current as any)?.blur();
                        }
                    }}
                />  
            </Col>
            <Col span={5} className='rgbbox'>
                <span className='text'>B: </span>
                {/* <span className='value'>{`${hsva?.h||hsva?.h===0 ? hsvaToRgba(hsva)?.b:''}`}</span> */}
                <input className='value'
                    ref={inputRef4}
                    style={{border:0}}
                    value={color2.b}
                    onChange={(e:any) => rgbachangeColor('b', e?.target?.value||"")}
                    onBlur={() => handClick({...hsva, ...rgbaToHsva(color2)})}
                    onKeyDown={(e) => {
                        if (e?.code === 'Enter') {
                            handClick({...hsva, ...rgbaToHsva(color2)});
                            if (inputRef4.current) (inputRef4.current as any)?.blur();
                        }
                    }}
                />  
            </Col>
            <Col span={18} id='wheelbox' style={{textAlign: 'center'}}>
                <Wheel
                    style={{width: `200px`, height: `200px`}}
                    color={wheelhsva}
                    onChange={(color) => {
                        setWheelHsva({ ...hsva, ...color.hsva });
                        handClick({ ...hsva, ...color.hsva });
                    }}
                    />

            </Col>
            <Col span={6}>
                <ShadeSlider
                    direction='vertical'
                    hsva={hsva}
                    className='shadeSlider'
                    style={{height: '200px', width: '16px',borderRadius: '9px', float:'right', marginRight: 30}}
                    // onChange={(newShade) => {
                    //     setHsva({ ...hsva, ...newShade });
                    // }}
                    onChange={(color) => handClick({ ...hsva, ...color })}
                />
            </Col>
            

            <Col span={24} className='cccc'>
                {
                    colors?.map((c:string) => (
                        <div
                            key={c}
                            style={{width: 30, height: 20, flex: 1, backgroundColor: `${c}`}}
                            // onClick={() => {
                            //     setHsva({...hsva, ...hexToHsva(c)})
                            // }}
                            onClick={() => {
                                handClick({...hsva, ...hexToHsva(c)})
                            }}
                        />
                    ))  
                }
                
            </Col>
       </Row>
       
    </div>
}

LightSetting.displayName = 'LightSetting';
export default LightSetting;