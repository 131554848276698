class HidRequest{
    device:any;
    requesting:boolean = false;
    requestGroup: any[] = [];
    nowCommd:number|null = null;
    devicefilter = [];
    reqLen = 63;
    
    constructor(filterd) {
        this.device = null;
        this.requesting = false;
        this.devicefilter = filterd;
        
    }
    setReqLen(reqLen:number) {
        this.reqLen = reqLen-1;
    }
    getReqLen() {
        return this.reqLen;
    }
    async getDevice() {
        const devices:any = await (navigator as any).hid.requestDevice({
            filters: [...this.devicefilter],
            // filters: [],
        });
        console.log(devices, 'devices')
        const poordevices:any = await (navigator as any).hid.getDevices();
        if (poordevices && poordevices?.length > 0) {
            const d = poordevices.filter((item:any) => item?.productId === devices[0]?.productId);
            if (d && d?.length === 1) {
                this.device = d[0];
            } else if (d && d?.length > 1) {
                d.forEach((ele:any) => {
                    if (ele && ele?.collections && ele?.collections?.length > 1) {
                        this.device = ele;
                    }
                });
            } else {
                this.device = devices[0];
            }
            
        }  else {
            this.device = devices[0];
        }
        return this.device;
    }   
 
    canRequest() {
        return !!this.device;
    }
    async canConnect() {
        const devices:any = await (navigator as any).hid.getDevices();
        return devices && devices?.length ? true : false;
    }
    async request(data:any) {
        try {
            if (!this.device) {
                alert('请连接设备');
                throw "Device is null";
            } else if (this.device && !this.device?.opened) {
                await this.device.open();
            } else if (!this.device?.opened) {
                throw "Device not opened";
            }
    
            if (this.requesting) {
                this.requestGroup.push(data);
                console.log("this.requestGroup.push(data)", data)
            } else {    
                const buffer:Uint8Array = new Uint8Array(this.reqLen);
                data?.forEach((ele:string|number, i:number) => {
                    buffer[i] = parseInt(ele+"", 16);
                });
                this.nowCommd = buffer[0];
                this.device.sendReport(6, buffer);
            }
            this.requesting = true;
        } catch (e) {
            console.log('报错', e)
        }
    }

    callback(commd:number) {
        if (commd !== this.nowCommd) return;
        if (!this.requestGroup || !this.requestGroup?.length) {
            this.requesting = false;
        } else {
            const [frist, ...arr] = this.requestGroup;
            try {
                if (!this.device) {
                    alert('请连接设备');
                    throw "Device is null";
                } else if (this.device && !this.device?.opened) {
                    this.device.open();
                } else if (!this.device?.opened) {
                    throw "Device not opened";
                }  
                this.requestGroup = arr;

                const buffer:Uint8Array = new Uint8Array(this.reqLen);
                frist?.forEach((ele:string|number, i:number) => {
                    buffer[i] = parseInt(ele+"", 16);
                });
                this.nowCommd = buffer[0];
                this.device?.sendReport(6, buffer);
                this.requesting = true; 
            } catch (e) {
                console.log('报错', e)
            }
        }
    }
}

export default HidRequest;