const allkeys:any = {
    "Esc": {
        id: 1,
        key: ['Esc'],
        keyCode: 27,
        code: 'Escape',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    }
};

const keys108 = {
    width: 39.8,
    height: 12.4,
    keys: [
        {
        id: 1,
        key: ['Esc'],
        pointer: [1,1],
        keyCode: 27,
        code: 'Escape',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 2,
        key: ['F1'],
        pointer: [4.4,1],
        keyCode: 112,
        code: 'F1',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 3,
        key: ['F2'],
        pointer: [6.1,1],
        keyCode: 113,
        code: 'F2',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 4,
        key: ['F3'],
        keyCode: 114,
        pointer: [7.8,1],
        code: 'F3',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 5,
        key: ['F4'],
        pointer: [9.5,1],
        keyCode: 115,
        code: 'F4',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 6,
        key: ['F5'],
        keyCode: 116,
        pointer: [12.05,1],
        code: 'F5',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 7,
        key: ['F6'],
        keyCode: 117,
        pointer: [13.75,1],
        code: 'F6',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 8,
        key: ['F7'],
        keyCode: 118,
        pointer: [15.45,1],
        code: 'F7',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 9,
        key: ['F8'],
        keyCode: 119,
        pointer: [17.15,1],
        code: 'F8',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 10,
        key: ['F9'],
        keyCode: 120,
        pointer: [19.7,1],
        code: 'F9',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 11,
        key: ['F10'],
        keyCode: 121,
        pointer: [21.4,1],
        code: 'F10',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 12,
        key: ['F11'],
        keyCode: 122,
        pointer: [23.1,1],
        code: 'F11',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 13,
        key: ['F12'],
        keyCode: 123,
        pointer: [24.8,1],
        code: 'F12',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 14,
        key: ['Print'],
        pointer: [26.8,1],
        keyCode: 154,
        code: 'PrintScreen',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {   
        id: 15,
        key: ['Scroll'],
        keyCode: 145,
        pointer: [28.5,1],
        code: 'ScrollLock',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
       
    {
        id: 16,
        key: ['Pause'],
        pointer: [30.2,1],
        keyCode: 35,
        code: 'Pause',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 17,
        key: ['VOL+'],
        keyCode: 33,
        pointer: [32.2, 1],
        code: 'VOL_ADD',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 18,
        key: ['VOL-'],
        pointer: [33.9,1],
        keyCode: 34,
        code: 'VOL_DEC',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 19,
        key: ['MUTE'],
        keyCode: 33,
        pointer: [35.6, 1],
        code: 'Mute',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 20,
        key: ['CALC'],
        pointer: [37.3,1],
        keyCode: 34,
        code: 'Calc',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 21,
        key: ['`', '~'],
        keyCode: 192,
        pointer: [1, 2.9],
        code: 'Backquote',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 22,
        key: ['1', '!'],
        keyCode: 49,
        pointer: [2.7, 2.9],
        code: 'Digit1',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 23,
        key: ['2','@'],
        keyCode: 50,
        pointer: [4.4,2.9],
        code: 'Digit2',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 24,
        key: ['3','#'],
        keyCode: 51,
        pointer: [6.1,2.9],
        code: 'Digit3',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 25,
        key: ['4', '$'],
        keyCode: 52,
        pointer: [7.8,2.9],
        code: 'Digit4',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 26,
        key: ['5', '%'],
        pointer: [9.5,2.9],
        keyCode: 53,
        code: 'Digit5',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 27,
        key: ['6', '^'],
        pointer: [11.2,2.9],
        keyCode: 54,
        code: 'Digit6',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 28,
        key: ['7', '&'],
        pointer: [12.9,2.9],
        keyCode: 55,
        code: 'Digit7',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 29,
        key: ['8','*'],
        pointer: [14.6,2.9],
        keyCode: 56,
        code: 'Digit8',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 30,
        key: ['9','('],
        pointer: [16.3,2.9],
        keyCode: 57,
        code: 'Digit9',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 31,
        key: ['0',')'],
        pointer: [18,2.9],
        keyCode: 48,
        code: 'Digit0',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 32,
        key: ['-','_'],
        pointer: [19.7,2.9],
        keyCode: 189,
        code: 'Minus',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 33,
        key: ['=','+'],
        pointer: [21.4,2.9],
        keyCode: 187,
        code: 'Equal',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 34,
        key: ['Backspace'],
        pointer: [23.1, 2.9],
        keyCode: 8,
        code: 'Backspace',
        message: {
            w: 3.2,
            h: 1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 35,
        key: ['Insert'],
        pointer: [26.8, 2.9],
        keyCode: 45,
        code: 'Insert',
        message: {
            w: 1.5,
            h: 1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 36,
        key: ['Home'],
        pointer: [28.5, 2.9],
        keyCode: 36,
        code: 'Home',
        message: {
            w: 1.5,
            h: 1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 37,
        key: ['PgUp'],
        pointer: [30.2, 2.9],
        keyCode: 33,
        code: 'PageUp',
        message: {
            w: 1.5,
            h: 1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 38,
        key: ['NUM'],
        pointer: [32.2 ,2.9],
        code: 'NumLock',
        keyCode: 144,
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },

    {
        id: 39,
        key: ['/'],
        pointer: [33.9,2.9],
        keyCode: 111,
        code: 'NumpadDivide',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 40,
        key: ['*'],
        pointer: [35.6,2.9],
        keyCode: 106,
        code: 'NumpadMultiply',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 41,
        key: ['-'],
        pointer: [37.3,2.9],
        keyCode: 109,
        code: 'NumpadSubtract',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 42,
        key: ['TAB'],
        pointer: [1, 4.6],
        keyCode: 9,
        code: 'Tab',
        message: {
            w: 2,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 43,
        key: ['Q'],
        pointer: [3.2, 4.6],
        keyCode: 81,
        code: 'KeyQ',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 44,
        key: ['W'],
        pointer: [4.9,4.6],
        keyCode: 87,
        code: 'KeyW',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 45,
        key: ['E'],
        pointer: [6.6,4.6],
        keyCode: 69,
        code: 'KeyE',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 46,
        key: ['R'],
        pointer: [8.3,4.6],
        keyCode: 82,
        code: 'KeyR',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 47,
        key: ['T'],
        keyCode: 84,
        pointer: [10,4.6],
        code: 'KeyT',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 48,
        key: ['Y'],
        pointer: [11.7,4.6],
        keyCode: 89,
        code: 'KeyY',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 49,
        key: ['U'],
        pointer: [13.4,4.6],
        keyCode: 85,
        code: 'KeyU',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 50,
        key: ['I'],
        pointer: [15.1,4.6],
        keyCode: 73,
        code: 'KeyI',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 51,
        key: ['O'],
        pointer: [16.8,4.6],
        keyCode: 79,
        code: 'KeyO',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 52,
        key: ['P'],
        pointer: [18.5,4.6],
        keyCode: 80,
        code: 'KeyP',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 53,
        key: ['[','{'],
        pointer: [20.2,4.6],
        keyCode: 219,
        code: 'BracketLeft',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 54,
        key: [']', '}'],
        pointer: [21.9,4.6],
        keyCode: 221,
        code: 'BracketRight',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 55,
        key: ['\\', '|'],
        pointer: [23.6,4.6],
        keyCode: 220,
        code: 'Backslash',
        message: {
            w: 2.7,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 56,
        key: ['Del'],
        pointer: [26.8, 4.6],
        keyCode: 46,
        code: 'Delete',
        message: {
            w: 1.5,
            h: 1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 57,
        key: ['End'],
        pointer: [28.5, 4.6],
        keyCode: 35,
        code: 'End',
        message: {
            w: 1.5,
            h: 1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 58,
        key: ['PgDn'],
        pointer: [30.2, 4.6],
        keyCode: 34,
        code: 'PageDown',
        message: {
            w: 1.5,
            h: 1.5,
            nw: 1,
            nh: 1   
        }
    },
    {
        id: 59,
        key: ['7'],
        pointer: [32.2,4.6],
        keyCode: 103,
        code: 'Numpad7',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },

    {
        id: 60,
        key: ['8'],
        pointer: [33.9,4.6],
        keyCode: 104,
        code: 'Numpad8',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 61,
        key: ['9'],
        pointer: [35.6,4.6],
        keyCode: 105,
        code: 'Numpad9',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 62,
        key: ['+'],
        pointer: [37.3,4.6],
        keyCode: 107,
        code: 'NumpadAdd',
        message: {
            w: 1.5,
            h: 3.2,
            nw: 1,
            nh: 1   
        }
    },
    {
        id: 63,
        key: ['CAPSLK'],
        pointer: [1, 6.3],
        keyCode: 20,
        code: 'CapsLock',
        message: {
            w: 2.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 64,
        key: ['A'],
        pointer: [3.7, 6.3],
        keyCode: 65,
        code: 'KeyA',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 65,
        key: ['S'],
        pointer: [5.4,6.3],
        keyCode: 83,
        code: 'KeyS',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 66,
        key: ['D'],
        pointer: [7.1,6.3],
        keyCode: 68,
        code: 'KeyD',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 67,
        key: ['F'],
        pointer: [8.8,6.3],
        keyCode: 70,
        code: 'KeyF',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 68,
        key: ['G'],
        pointer: [10.5,6.3],
        keyCode: 71,
        code: 'KeyG',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 69,
        key: ['H'],
        pointer: [12.2,6.3],
        keyCode: 72,
        code: 'KeyH',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 70,
        key: ['J'],
        pointer: [13.9,6.3],
        keyCode: 74,
        code: 'KeyJ',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 71,
        key: ['K'],
        pointer: [15.6,6.3],
        keyCode: 75,
        code: 'KeyK',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 72,
        key: ['L'],
        pointer: [17.3,6.3],
        keyCode: 76,
        code: 'KeyL',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 73,
        key: [';',':'],
        pointer: [19,6.3],
        keyCode: 186,
        code: 'Semicolon',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 74,
        key: ['\'','"'],
        pointer: [20.7,6.3],
        keyCode: 220,
        code: 'Quote',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 75,
        key: ['ENTER'],
        pointer: [22.4,6.3],
        keyCode: 13,
        code: 'Enter',
        message: {
            w: 3.9,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 77,
        key: ['4'],
        pointer: [32.2,6.3],
        keyCode: 100,
        code: 'Numpad4',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },

    {
        id: 78,
        key: ['5'],
        pointer: [33.9,6.3],
        keyCode: 101,
        code: 'Numpad5',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 79,
        key: ['6'],
        pointer: [35.6,6.3],
        keyCode: 102,
        code: 'Numpad6',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 76,
        key: ['SHIFT'],
        pointer: [1, 8],
        keyCode: 16,
        code: 'ShiftLeft',
        message: {
            w: 3.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 80,
        key: ['Z'],
        pointer: [4.7, 8],
        keyCode: 90,
        code: 'KeyZ',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 81,
        key: ['X'],
        pointer: [6.4,8],
        keyCode: 88,
        code: 'KeyX',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 82,
        key: ['C'],
        pointer: [8.1,8],
        keyCode: 67,
        code: 'KeyC',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 83,
        key: ['V'],
        pointer: [9.8,8],
        keyCode: 86,
        code: 'KeyV',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 84,
        key: ['B'],
        pointer: [11.5,8],
        keyCode: 66,
        code: 'KeyB',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 85,
        key: ['N'],
        pointer: [13.2,8],
        keyCode: 78,
        code: 'KeyN',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 86,
        key: ['M'],
        pointer: [14.9,8],
        keyCode: 77,
        code: 'KeyM',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 87,
        key: [',','<'],
        pointer: [16.6,8],
        keyCode: 188,
        code: 'Comma',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 88,
        key: ['.','>'],
        pointer: [18.3,8],
        keyCode: 190,
        code: 'Period',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 89,
        key: ['/','?'],
        pointer: [20,8],
        keyCode: 191,
        code: 'Slash',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 90,
        key: ['SHIFT'],
        pointer: [21.7,8],
        keyCode: 16,
        code: 'ShiftRight',
        message: {
            w: 4.6,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 91,
        key: ['↑'],
        pointer: [28.5,8],
        keyCode: 38,
        code: 'ArrowUp',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 92,
        key: ['1'],
        pointer: [32.2,8],
        keyCode: 97,
        code: 'Numpad1',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },

    {
        id: 93,
        key: ['2'],
        pointer: [33.9,8],
        keyCode: 98,
        code: 'Numpad2',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 94,
        key: ['3'],
        pointer: [35.6,8],
        keyCode: 99,
        code: 'Numpad3',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 95,
        key: ['CTRL'],
        pointer: [1,9.7],
        keyCode: 17,
        code: 'ControlLeft',
        message: {
            w: 1.6,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 96,
        key: ['WIN'],
        pointer: [2.8,9.7],
        keyCode: 91,
        code: 'MetaLeft',
        message: {
            w: 1.6,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 97,
        key: ['ALT'],
        pointer: [4.6,9.7],
        keyCode: 18,
        code: 'AltLeft',
        message: {
            w: 1.6,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 98,
        key: ['_'],
        pointer: [6.4,9.7],
        keyCode: 32,
        code: 'Space',
        message: {
            w: 12.7,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 99,
        key: ['ALT'],
        code: 'AltRight',
        keyCode: 18,
        pointer: [19.3,9.7],
        message: {
            w: 1.6,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 100,
        key: ['FN'],
        keyCode: 255,
        pointer: [21.1,9.7],
        code: 'WakeUp',
        message: {
            w: 1.6,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 108,
        key: ['App'],
        keyCode: 255,
        pointer: [22.9,9.7],
        code: 'App',
        message: {
            w: 1.6,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 101,
        key: ['CTRL'],
        pointer: [24.7,9.7],
        code: 'ControlRight',
        keyCode: 17,
        message: {
            w: 1.6,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    
    {
        id: 102,
        key: ['←'],
        pointer: [26.7,9.7],
        keyCode: 37,
        code: 'ArrowLeft',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 103,
        key: ['↓'],
        pointer: [28.5,9.7],
        keyCode: 40,
        code: 'ArrowDown',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 104,
        key: ['→'],
        pointer: [30.2,9.7],
        keyCode: 39,
        code: 'ArrowRight',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 105,
        key: ['0'],
        pointer: [32.2,9.7],
        keyCode: 96,
        code: 'Numpad0',
        message: {
            w: 3.2,
            h: 1.5,
            nw: 1,
            nh: 1
        }
    }, 
    {
        id: 106,
        key: ['.'],
        pointer: [35.6,9.7],
        keyCode: 110,
        code: 'NumpadDecimal',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 107,
        key: ['ENTER'],
        pointer: [37.3, 8],
        keyCode: 13,
        code: 'NumpadEnter',
        message: {
            w: 1.5,
            h: 3.2,
            nw: 1,
            nh: 1
        }
    }, 
    ]
};


// const keys103 = {
//     width: 37.5,
//     height: 11.5,
//     keys: [
//         {
//         id: 1,
//         key: ['Esc'],
//         pointer: [1,1],
//         keyCode: 27,
//         code: 'Escape',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 2,
//         key: ['F1'],
//         pointer: [4.2,1],
//         keyCode: 112,
//         code: 'F1',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 3,
//         key: ['F2'],
//         pointer: [5.8,1],
//         keyCode: 113,
//         code: 'F2',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 4,
//         key: ['F3'],
//         keyCode: 114,
//         pointer: [7.4,1],
//         code: 'F3',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 5,
//         key: ['F4'],
//         pointer: [9,1],
//         keyCode: 115,
//         code: 'F4',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 6,
//         key: ['F5'],
//         keyCode: 116,
//         pointer: [11.4,1],
//         code: 'F5',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 7,
//         key: ['F6'],
//         keyCode: 117,
//         pointer: [13,1],
//         code: 'F6',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 8,
//         key: ['F7'],
//         keyCode: 118,
//         pointer: [14.6,1],
//         code: 'F7',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 9,
//         key: ['F8'],
//         keyCode: 119,
//         pointer: [16.2,1],
//         code: 'F8',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 10,
//         key: ['F9'],
//         keyCode: 120,
//         pointer: [18.6,1],
//         code: 'F9',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 11,
//         key: ['F10'],
//         keyCode: 121,
//         pointer: [20.2,1],
//         code: 'F10',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 12,
//         key: ['F11'],
//         keyCode: 122,
//         pointer: [21.8,1],
//         code: 'F11',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 13,
//         key: ['F12'],
//         keyCode: 123,
//         pointer: [23.4,1],
//         code: 'F12',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
        
//     },
//     {
//         id: 11,
//         key: ['PRTSC'],
//         keyCode: 154,
//         pointer: [25.2,1],
//         code: 'PrintScreen',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 12,
//         key: ['SCRLK'],
//         keyCode: 145,
//         pointer: [26.8,1],
//         code: 'ScrollLock',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 13,
//         key: ['PAUSE'],
//         keyCode: 19,
//         pointer: [28.4,1],
//         code: 'Pause',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 14,
//         key: ['`', '~'],
//         keyCode: 192,
//         pointer: [1, 2.6],
//         code: 'Backquote',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 15,
//         key: ['1', '!'],
//         keyCode: 49,
//         pointer: [2.6, 2.6],
//         code: 'Digit1',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 16,
//         key: ['2','@'],
//         keyCode: 50,
//         pointer: [4.2,2.6],
//         code: 'Digit2',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 17,
//         key: ['3','#'],
//         keyCode: 51,
//         pointer: [5.8,2.6],
//         code: 'Digit3',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 18,
//         key: ['4', '$'],
//         keyCode: 52,
//         pointer: [7.4,2.6],
//         code: 'Digit4',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 19,
//         key: ['5', '%'],
//         pointer: [9,2.6],
//         keyCode: 53,
//         code: 'Digit5',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 20,
//         key: ['6', '^'],
//         pointer: [10.6,2.6],
//         keyCode: 54,
//         code: 'Digit6',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 21,
//         key: ['7', '&'],
//         pointer: [12.2,2.6],
//         keyCode: 55,
//         code: 'Digit7',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 22,
//         key: ['8','*'],
//         pointer: [13.8,2.6],
//         keyCode: 56,
//         code: 'Digit8',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 23,
//         key: ['9','('],
//         pointer: [15.4,2.6],
//         keyCode: 57,
//         code: 'Digit9',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 24,
//         key: ['0',')'],
//         pointer: [17,2.6],
//         keyCode: 48,
//         code: 'Digit0',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 25,
//         key: ['-','_'],
//         pointer: [18.6,2.6],
//         keyCode: 189,
//         code: 'Minus',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 26,
//         key: ['=','+'],
//         pointer: [20.2,2.6],
//         keyCode: 187,
//         code: 'Equal',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 27,
//         key: ['Backspace'],
//         pointer: [21.8, 2.6],
//         keyCode: 8,
//         code: 'Backspace',
//         message: {
//             w: 3.1,
//             h: 1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 28,
//         key: ['INS'],
//         pointer: [25.2,2.6],
//         keyCode: 45,
//         code: 'Insert',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 29,
//         key: ['HOME'],
//         keyCode: 36,
//         pointer: [26.8,2.6],
//         code: 'Home',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 30,
//         key: ['PGUP'],
//         keyCode: 33,
//         pointer: [28.4, 2.6],
//         code: 'PageUp',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 31,
//         key: ['TAB'],
//         pointer: [1, 4.2],
//         keyCode: 9,
//         code: 'Tab',
//         message: {
//             w: 2.35,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 32,
//         key: ['Q'],
//         pointer: [3.45, 4.2],
//         keyCode: 81,
//         code: 'KeyQ',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 33,
//         key: ['W'],
//         pointer: [5.05,4.2],
//         keyCode: 87,
//         code: 'KeyW',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 34,
//         key: ['E'],
//         pointer: [6.65,4.2],
//         keyCode: 69,
//         code: 'KeyE',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 35,
//         key: ['R'],
//         pointer: [8.25,4.2],
//         keyCode: 82,
//         code: 'KeyR',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 36,
//         key: ['T'],
//         keyCode: 84,
//         pointer: [9.85,4.2],
//         code: 'KeyT',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 37,
//         key: ['Y'],
//         pointer: [11.45,4.2],
//         keyCode: 89,
//         code: 'KeyY',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 38,
//         key: ['U'],
//         pointer: [13.05,4.2],
//         keyCode: 85,
//         code: 'KeyU',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 39,
//         key: ['I'],
//         pointer: [14.65,4.2],
//         keyCode: 73,
//         code: 'KeyI',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 40,
//         key: ['O'],
//         pointer: [16.25,4.2],
//         keyCode: 79,
//         code: 'KeyO',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 41,
//         key: ['P'],
//         pointer: [17.85,4.2],
//         keyCode: 80,
//         code: 'KeyP',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 42,
//         key: ['[','{'],
//         pointer: [19.45,4.2],
//         keyCode: 219,
//         code: 'BracketLeft',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 43,
//         key: [']', '}'],
//         pointer: [21.05,4.2],
//         keyCode: 221,
//         code: 'BracketRight',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 44,
//         key: ['\\', '|'],
//         pointer: [22.65,4.2],
//         keyCode: 220,
//         code: 'Backslash',
//         message: {
//             w: 2.25,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 45,
//         key: ['DEL'],
//         pointer: [25.2,4.2],
//         keyCode: 46,
//         code: 'Delete',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 46,
//         key: ['END'],
//         pointer: [26.8,4.2],
//         keyCode: 35,
//         code: 'End',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 47,
//         key: ['PGDN'],
//         pointer: [28.4,4.2],
//         keyCode: 34,
//         code: 'PageDown',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 48,
//         key: ['CAPSLK'],
//         pointer: [1, 5.8],
//         keyCode: 20,
//         code: 'CapsLock',
//         message: {
//             w: 2.8,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 49,
//         key: ['A'],
//         pointer: [3.9, 5.8],
//         keyCode: 65,
//         code: 'KeyA',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 50,
//         key: ['S'],
//         pointer: [5.5,5.8],
//         keyCode: 83,
//         code: 'KeyS',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 51,
//         key: ['D'],
//         pointer: [7.1,5.8],
//         keyCode: 68,
//         code: 'KeyD',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 52,
//         key: ['F'],
//         pointer: [8.7,5.8],
//         keyCode: 70,
//         code: 'KeyF',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 53,
//         key: ['G'],
//         pointer: [10.3,5.8],
//         keyCode: 71,
//         code: 'KeyG',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 55,
//         key: ['H'],
//         pointer: [11.9,5.8],
//         keyCode: 72,
//         code: 'KeyH',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 56,
//         key: ['J'],
//         pointer: [13.5,5.8],
//         keyCode: 74,
//         code: 'KeyJ',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 57,
//         key: ['K'],
//         pointer: [15.1,5.8],
//         keyCode: 75,
//         code: 'KeyK',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 58,
//         key: ['L'],
//         pointer: [16.7,5.8],
//         keyCode: 76,
//         code: 'KeyL',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 59,
//         key: [';',':'],
//         pointer: [18.3,5.8],
//         keyCode: 186,
//         code: 'Semicolon',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 60,
//         key: ['\'','"'],
//         pointer: [19.9,5.8],
//         keyCode: 220,
//         code: 'Backslash',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 61,
//         key: ['ENTER'],
//         pointer: [21.5,5.8],
//         keyCode: 13,
//         code: 'Enter',
//         message: {
//             w: 3.4,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 62,
//         key: ['SHIFT'],
//         pointer: [1, 7.4],
//         keyCode: 16,
//         code: 'ShiftLeft',
//         message: {
//             w: 3.55,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 63,
//         key: ['Z'],
//         pointer: [4.65, 7.4],
//         keyCode: 90,
//         code: 'KeyZ',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 64,
//         key: ['X'],
//         pointer: [6.25,7.4],
//         keyCode: 88,
//         code: 'KeyX',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 65,
//         key: ['C'],
//         pointer: [7.85,7.4],
//         keyCode: 67,
//         code: 'KeyC',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 66,
//         key: ['V'],
//         pointer: [9.45,7.4],
//         keyCode: 86,
//         code: 'KeyV',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 67,
//         key: ['B'],
//         pointer: [11.05,7.4],
//         keyCode: 66,
//         code: 'KeyB',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 68,
//         key: ['N'],
//         pointer: [12.65,7.4],
//         keyCode: 78,
//         code: 'KeyN',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 69,
//         key: ['M'],
//         pointer: [14.25,7.4],
//         keyCode: 77,
//         code: 'KeyM',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 70,
//         key: [',','<'],
//         pointer: [15.85,7.4],
//         keyCode: 188,
//         code: 'Comma',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 71,
//         key: ['.','>'],
//         pointer: [17.45,7.4],
//         keyCode: 190,
//         code: 'Period',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 72,
//         key: ['/','?'],
//         pointer: [19.05,7.4],
//         keyCode: 191,
//         code: 'Slash',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 73,
//         key: ['SHIFT'],
//         pointer: [20.65,7.4],
//         keyCode: 16,
//         code: 'ShiftRight',
//         message: {
//             w: 4.25,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 75,
//         key: ['CTRL'],
//         pointer: [1,9],
//         keyCode: 17,
//         code: 'ControlLeft',
//         message: {
//             w: 2,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 76,
//         key: ['WIN'],
//         pointer: [3.1,9],
//         keyCode: 91,
//         code: 'MetaLeft',
//         message: {
//             w: 2,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 77,
//         key: ['ALT'],
//         pointer: [5.2,9],
//         keyCode: 18,
//         code: 'AltLeft',
//         message: {
//             w: 2,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 78,
//         key: ['_'],
//         pointer: [7.3,9],
//         keyCode: 32,
//         code: 'Space',
//         message: {
//             w: 9.2,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 79,
//         key: ['ALT'],
//         code: 'AltRight',
//         keyCode: 18,
//         pointer: [16.6,9],
//         message: {
//             w: 2,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 80,
//         key: ['FN'],
//         keyCode: 92,
//         pointer: [18.7,9],
//         code: 'MetaRight',
//         message: {
//             w: 2,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 81,
//         key: ['PAGE'],
//         keyCode: 93,
//         pointer: [20.8,9],
//         code: 'ContextMenu',
//         message: {
//             w: 2,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 82,
//         key: ['CTRL'],
//         pointer: [22.9,9],
//         code: 'ControlRight',
//         keyCode: 17,
//         message: {
//             w: 2,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 74,
//         key: ['↑'],
//         pointer: [26.8,7.4],
//         keyCode: 38,
//         code: 'ArrowUp',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 83,
//         key: ['←'],
//         pointer: [25.2,9],
//         keyCode: 37,
//         code: 'ArrowLeft',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 84,
//         key: ['↓'],
//         pointer: [26.8,9],
//         keyCode: 40,
//         code: 'ArrowDown',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 85,
//         key: ['→'],
//         pointer: [28.4,9],
//         keyCode: 39,
//         code: 'ArrowRight',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 86,
//         key: ['NUM'],
//         pointer: [30.2,2.6],
//         code: 'NumLock',
//         keyCode: 144,
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },

//     {
//         id: 87,
//         key: ['/'],
//         pointer: [31.8,2.6],
//         keyCode: 111,
//         code: 'NumpadDivide',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 88,
//         key: ['*'],
//         pointer: [33.4,2.6],
//         keyCode: 106,
//         code: 'NumpadMultiply',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 89,
//         key: ['-'],
//         pointer: [35,2.6],
//         keyCode: 109,
//         code: 'NumpadSubtract',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 90,
//         key: ['7'],
//         pointer: [30.2,4.2],
//         keyCode: 103,
//         code: 'Numpad7',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },

//     {
//         id: 91,
//         key: ['8'],
//         pointer: [31.8,4.2],
//         keyCode: 104,
//         code: 'Numpad8',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 92,
//         key: ['9'],
//         pointer: [33.4,4.2],
//         keyCode: 105,
//         code: 'Numpad9',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 93,
//         key: ['+'],
//         pointer: [35,4.2],
//         keyCode: 107,
//         code: 'NumpadAdd',
//         message: {
//             w: 1.5,
//             h: 3.1,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 94,
//         key: ['4'],
//         pointer: [30.2,5.8],
//         keyCode: 100,
//         code: 'Numpad4',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },

//     {
//         id: 95,
//         key: ['5'],
//         pointer: [31.8,5.8],
//         keyCode: 101,
//         code: 'Numpad5',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 96,
//         key: ['6'],
//         pointer: [33.4,5.8],
//         keyCode: 102,
//         code: 'Numpad6',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 98,
//         key: ['1'],
//         pointer: [30.2,7.4],
//         keyCode: 97,
//         code: 'Numpad1',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },

//     {
//         id: 99,
//         key: ['2'],
//         pointer: [31.8,7.4],
//         keyCode: 98,
//         code: 'Numpad2',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 100,
//         key: ['3'],
//         pointer: [33.4,7.4],
//         keyCode: 99,
//         code: 'Numpad3',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 101,
//         key: ['0'],
//         pointer: [30.2,9],
//         keyCode: 96,
//         code: 'Numpad0',
//         message: {
//             w: 3.1,
//             h: 1.5,
//             nw: 1,
//             nh: 1
//         }
//     }, 
//     {
//         id: 102,
//         key: ['.'],
//         pointer: [33.4,9],
//         keyCode: 110,
//         code: 'NumpadDecimal',
//         message: {
//             w: 1.5,
//             h:1.5,
//             nw: 1,
//             nh: 1
//         }
//     },
//     {
//         id: 103,
//         key: ['ENTER'],
//         pointer: [35, 7.4],
//         keyCode: 13,
//         code: 'NumpadEnter',
//         message: {
//             w: 1.5,
//             h: 3.1,
//             nw: 1,
//             nh: 1
//         }
//     }, 
//     ]
// };
const keys98 = {
    width: 34.7,
    height: 12.4,
    keys: [
        {
        id: 1,
        key: ['Esc'],
        pointer: [1,1],
        keyCode: 27,
        code: 'Escape',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 2,
        key: ['F1'],
        pointer: [2.7,1],
        keyCode: 112,
        code: 'F1',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 3,
        key: ['F2'],
        pointer: [4.4,1],
        keyCode: 113,
        code: 'F2',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 4,
        key: ['F3'],
        keyCode: 114,
        pointer: [6.1,1],
        code: 'F3',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 5,
        key: ['F4'],
        pointer: [7.8,1],
        keyCode: 115,
        code: 'F4',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 6,
        key: ['F5'],
        keyCode: 116,
        pointer: [9.5,1],
        code: 'F5',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 7,
        key: ['F6'],
        keyCode: 117,
        pointer: [11.2,1],
        code: 'F6',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 8,
        key: ['F7'],
        keyCode: 118,
        pointer: [12.9,1],
        code: 'F7',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 9,
        key: ['F8'],
        keyCode: 119,
        pointer: [14.6,1],
        code: 'F8',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 10,
        key: ['F9'],
        keyCode: 120,
        pointer: [16.3,1],
        code: 'F9',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 11,
        key: ['F10'],
        keyCode: 121,
        pointer: [18,1],
        code: 'F10',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 12,
        key: ['F11'],
        keyCode: 122,
        pointer: [19.7,1],
        code: 'F11',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 13,
        key: ['F12'],
        keyCode: 123,
        pointer: [21.4,1],
        code: 'F12',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 45,
        key: ['DEL'],
        pointer: [23.1,1],
        keyCode: 46,
        code: 'Delete',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 29,
        key: ['HOME'],
        keyCode: 36,
        pointer: [24.8,1],
        code: 'Home',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
       
    {
        id: 46,
        key: ['END'],
        pointer: [27.1,1],
        keyCode: 35,
        code: 'End',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 30,
        key: ['PGUP'],
        keyCode: 33,
        pointer: [28.8, 1],
        code: 'PageUp',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 47,
        key: ['PGDN'],
        pointer: [30.5,1],
        keyCode: 34,
        code: 'PageDown',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 14,
        key: ['`', '~'],
        keyCode: 192,
        pointer: [1, 2.9],
        code: 'Backquote',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 15,
        key: ['1', '!'],
        keyCode: 49,
        pointer: [2.7, 2.9],
        code: 'Digit1',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 16,
        key: ['2','@'],
        keyCode: 50,
        pointer: [4.4,2.9],
        code: 'Digit2',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 17,
        key: ['3','#'],
        keyCode: 51,
        pointer: [6.1,2.9],
        code: 'Digit3',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 18,
        key: ['4', '$'],
        keyCode: 52,
        pointer: [7.8,2.9],
        code: 'Digit4',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 19,
        key: ['5', '%'],
        pointer: [9.5,2.9],
        keyCode: 53,
        code: 'Digit5',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 20,
        key: ['6', '^'],
        pointer: [11.2,2.9],
        keyCode: 54,
        code: 'Digit6',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 21,
        key: ['7', '&'],
        pointer: [12.9,2.9],
        keyCode: 55,
        code: 'Digit7',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 22,
        key: ['8','*'],
        pointer: [14.6,2.9],
        keyCode: 56,
        code: 'Digit8',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 23,
        key: ['9','('],
        pointer: [16.3,2.9],
        keyCode: 57,
        code: 'Digit9',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 24,
        key: ['0',')'],
        pointer: [18,2.9],
        keyCode: 48,
        code: 'Digit0',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 25,
        key: ['-','_'],
        pointer: [19.7,2.9],
        keyCode: 189,
        code: 'Minus',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 26,
        key: ['=','+'],
        pointer: [21.4,2.9],
        keyCode: 187,
        code: 'Equal',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 27,
        key: ['Backspace'],
        pointer: [23.1, 2.9],
        keyCode: 8,
        code: 'Backspace',
        message: {
            w: 3.2,
            h: 1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 86,
        key: ['NUM'],
        pointer: [27.1,2.9],
        code: 'NumLock',
        keyCode: 144,
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },

    {
        id: 87,
        key: ['/'],
        pointer: [28.8,2.9],
        keyCode: 111,
        code: 'NumpadDivide',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 88,
        key: ['*'],
        pointer: [30.5,2.9],
        keyCode: 106,
        code: 'NumpadMultiply',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 89,
        key: ['-'],
        pointer: [32.3,2.9],
        keyCode: 109,
        code: 'NumpadSubtract',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 31,
        key: ['TAB'],
        pointer: [1, 4.6],
        keyCode: 9,
        code: 'Tab',
        message: {
            w: 1.8,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 32,
        key: ['Q'],
        pointer: [3, 4.6],
        keyCode: 81,
        code: 'KeyQ',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 33,
        key: ['W'],
        pointer: [4.7,4.6],
        keyCode: 87,
        code: 'KeyW',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 34,
        key: ['E'],
        pointer: [6.4,4.6],
        keyCode: 69,
        code: 'KeyE',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 35,
        key: ['R'],
        pointer: [8.1,4.6],
        keyCode: 82,
        code: 'KeyR',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 36,
        key: ['T'],
        keyCode: 84,
        pointer: [9.8,4.6],
        code: 'KeyT',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 37,
        key: ['Y'],
        pointer: [11.5,4.6],
        keyCode: 89,
        code: 'KeyY',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 38,
        key: ['U'],
        pointer: [13.2,4.6],
        keyCode: 85,
        code: 'KeyU',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 39,
        key: ['I'],
        pointer: [14.9,4.6],
        keyCode: 73,
        code: 'KeyI',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 40,
        key: ['O'],
        pointer: [16.6,4.6],
        keyCode: 79,
        code: 'KeyO',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 41,
        key: ['P'],
        pointer: [18.3,4.6],
        keyCode: 80,
        code: 'KeyP',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 42,
        key: ['[','{'],
        pointer: [20,4.6],
        keyCode: 219,
        code: 'BracketLeft',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 43,
        key: [']', '}'],
        pointer: [21.7,4.6],
        keyCode: 221,
        code: 'BracketRight',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 44,
        key: ['\\', '|'],
        pointer: [23.4,4.6],
        keyCode: 220,
        code: 'Backslash',
        message: {
            w: 2.9,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 90,
        key: ['7'],
        pointer: [27.1,4.6],
        keyCode: 103,
        code: 'Numpad7',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },

    {
        id: 91,
        key: ['8'],
        pointer: [28.8,4.6],
        keyCode: 104,
        code: 'Numpad8',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 92,
        key: ['9'],
        pointer: [30.5,4.6],
        keyCode: 105,
        code: 'Numpad9',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 93,
        key: ['+'],
        pointer: [32.3,4.6],
        keyCode: 107,
        code: 'NumpadAdd',
        message: {
            w: 1.5,
            h: 3.2,
            nw: 1,
            nh: 1   
        }
    },
    {
        id: 48,
        key: ['CAPSLK'],
        pointer: [1, 6.3],
        keyCode: 20,
        code: 'CapsLock',
        message: {
            w: 2.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 49,
        key: ['A'],
        pointer: [3.7, 6.3],
        keyCode: 65,
        code: 'KeyA',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 50,
        key: ['S'],
        pointer: [5.4,6.3],
        keyCode: 83,
        code: 'KeyS',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 51,
        key: ['D'],
        pointer: [7.1,6.3],
        keyCode: 68,
        code: 'KeyD',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 52,
        key: ['F'],
        pointer: [8.8,6.3],
        keyCode: 70,
        code: 'KeyF',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 53,
        key: ['G'],
        pointer: [10.5,6.3],
        keyCode: 71,
        code: 'KeyG',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 55,
        key: ['H'],
        pointer: [12.2,6.3],
        keyCode: 72,
        code: 'KeyH',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 56,
        key: ['J'],
        pointer: [13.9,6.3],
        keyCode: 74,
        code: 'KeyJ',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 57,
        key: ['K'],
        pointer: [15.6,6.3],
        keyCode: 75,
        code: 'KeyK',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 58,
        key: ['L'],
        pointer: [17.3,6.3],
        keyCode: 76,
        code: 'KeyL',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 59,
        key: [';',':'],
        pointer: [19,6.3],
        keyCode: 186,
        code: 'Semicolon',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 60,
        key: ['\'','"'],
        pointer: [20.7,6.3],
        keyCode: 220,
        code: 'Quote',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 61,
        key: ['ENTER'],
        pointer: [22.4,6.3],
        keyCode: 13,
        code: 'Enter',
        message: {
            w: 3.9,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 62,
        key: ['SHIFT'],
        pointer: [1, 8],
        keyCode: 16,
        code: 'ShiftLeft',
        message: {
            w: 3,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 94,
        key: ['4'],
        pointer: [27.1,6.3],
        keyCode: 100,
        code: 'Numpad4',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },

    {
        id: 95,
        key: ['5'],
        pointer: [28.8,6.3],
        keyCode: 101,
        code: 'Numpad5',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 96,
        key: ['6'],
        pointer: [30.5,6.3],
        keyCode: 102,
        code: 'Numpad6',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 63,
        key: ['Z'],
        pointer: [4.2, 8],
        keyCode: 90,
        code: 'KeyZ',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 64,
        key: ['X'],
        pointer: [5.9,8],
        keyCode: 88,
        code: 'KeyX',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 65,
        key: ['C'],
        pointer: [7.6,8],
        keyCode: 67,
        code: 'KeyC',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 66,
        key: ['V'],
        pointer: [9.3,8],
        keyCode: 86,
        code: 'KeyV',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 67,
        key: ['B'],
        pointer: [11,8],
        keyCode: 66,
        code: 'KeyB',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 68,
        key: ['N'],
        pointer: [12.7,8],
        keyCode: 78,
        code: 'KeyN',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 69,
        key: ['M'],
        pointer: [14.4,8],
        keyCode: 77,
        code: 'KeyM',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 70,
        key: [',','<'],
        pointer: [16.1,8],
        keyCode: 188,
        code: 'Comma',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 71,
        key: ['.','>'],
        pointer: [17.8,8],
        keyCode: 190,
        code: 'Period',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 72,
        key: ['/','?'],
        pointer: [19.5,8],
        keyCode: 191,
        code: 'Slash',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 73,
        key: ['SHIFT'],
        pointer: [21.2,8],
        keyCode: 16,
        code: 'ShiftRight',
        message: {
            w: 3,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 74,
        key: ['↑'],
        pointer: [25,8],
        keyCode: 38,
        code: 'ArrowUp',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 98,
        key: ['1'],
        pointer: [27.1,8],
        keyCode: 97,
        code: 'Numpad1',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },

    {
        id: 99,
        key: ['2'],
        pointer: [28.8,8],
        keyCode: 98,
        code: 'Numpad2',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 100,
        key: ['3'],
        pointer: [30.5,8],
        keyCode: 99,
        code: 'Numpad3',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 75,
        key: ['CTRL'],
        pointer: [1,9.7],
        keyCode: 17,
        code: 'ControlLeft',
        message: {
            w: 2,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 76,
        key: ['WIN'],
        pointer: [3.2,9.7],
        keyCode: 91,
        code: 'MetaLeft',
        message: {
            w: 2,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 77,
        key: ['ALT'],
        pointer: [5.4,9.7],
        keyCode: 18,
        code: 'AltLeft',
        message: {
            w: 2,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 78,
        key: ['_'],
        pointer: [7.6,9.7],
        keyCode: 32,
        code: 'Space',
        message: {
            w: 8.3,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 79,
        key: ['ALT'],
        code: 'AltRight',
        keyCode: 18,
        pointer: [16.1,9.7],
        message: {
            w: 2,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 80,
        key: ['FN'],
        keyCode: 255,
        pointer: [18.3,9.7],
        code: 'WakeUp',
        message: {
            w: 2,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 82,
        key: ['CTRL'],
        pointer: [20.5,9.7],
        code: 'ControlRight',
        keyCode: 17,
        message: {
            w: 2,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    
    {
        id: 83,
        key: ['←'],
        pointer: [23.3,9.7],
        keyCode: 37,
        code: 'ArrowLeft',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 84,
        key: ['↓'],
        pointer: [25,9.7],
        keyCode: 40,
        code: 'ArrowDown',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 85,
        key: ['→'],
        pointer: [26.7,9.7],
        keyCode: 39,
        code: 'ArrowRight',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 101,
        key: ['0'],
        pointer: [28.8,9.7],
        keyCode: 96,
        code: 'Numpad0',
        message: {
            w: 1.5,
            h: 1.5,
            nw: 1,
            nh: 1
        }
    }, 
    {
        id: 102,
        key: ['.'],
        pointer: [30.5,9.7],
        keyCode: 110,
        code: 'NumpadDecimal',
        message: {
            w: 1.5,
            h:1.5,
            nw: 1,
            nh: 1
        }
    },
    {
        id: 103,
        key: ['ENTER'],
        pointer: [32.3, 8],
        keyCode: 13,
        code: 'NumpadEnter',
        message: {
            w: 1.5,
            h: 3.2,
            nw: 1,
            nh: 1
        }
    }, 
    ]
};

export const keysTypes:any = {
    '49288': keys98, //Gaming Keyboard
    '12410': keys108, //Z108 有线
    '49271': keys108, //Wireless Receiver (Z108 无线)
}
const keyTypeNums:any = {
    'GamingKeyboard': {
       kbbase:'0x80',
       kbclick:'0x81',
       kbmedia:'0x90',
       kbsys:'0xa0',
       msbase:'0xb0',
       msre:'0xb1',
       msdpi:'0xb6',
       msscroll:'0xb2',
       msh:'0xb3',
       hong1: '0Xc0',
       hong2: '0Xc1',
       fun: '0xd0',
       text: '0xd1',
       fn: '0xe0',
    },
    'Z108': {
        kbbase:'0x10',
        kbclick:'0x11',
        kbmedia:'0x20',
        kbsys:'0x30',
        msbase:'0x40',
        msre:'0x41',
        msscroll:'0x42',
        msh:'0x43',
        msdpi:'0x46',
        hong1: '0X50',
        hong2: '0X51',
        fun: '0x60',
        text: '0x61',
        fn: '0x70',
     }
};

export const keyTypeNum = {
    k: {
        ...keyTypeNums[`GamingKeyboard`]
    },
    get(str: string) {
        return str && this?.k?.[str] ? this?.k?.[str]:'';
    },
    set(pid:string) {
        const id:string = pid === '49288' ? 'GamingKeyboard' : "Z108";
        this.k = id && keyTypeNums?.[id] ? {...keyTypeNums?.[id]}:{};
    },
    getCodeValue() {
        return {
            'PrintScreen': [this?.k?.['kbbase'], "0x00", "0x46"],
            'ScrollLock': [this?.k?.['kbbase'], "0x00", "0x47"],
            'Pause': [this?.k?.['kbbase'], "0x00", "0x48"],
            'Insert': [this?.k?.['kbbase'], "0x00", "0x49"],
            'Escape': [this?.k?.['kbbase'], "0x00", "0x29"],
            'App': [this?.k?.['kbbase'], "0x00", "0x65"],
            'F1': [this?.k?.['kbbase'], "0x00", "0x3a"],
            'F2': [this?.k?.['kbbase'], "0x00", "0x3b"],
            'F3': [this?.k?.['kbbase'], "0x00", "0x3c"],
            'F4': [this?.k?.['kbbase'], "0x00", "0x3d"],
            'F5': [this?.k?.['kbbase'], "0x00", "0x3e"],
            'F6': [this?.k?.['kbbase'], "0x00", "0x3f"],
            'F7': [this?.k?.['kbbase'], "0x00", "0x40"],
            'F8': [this?.k?.['kbbase'], "0x00", "0x41"],
            'F9': [this?.k?.['kbbase'], "0x00", "0x42"],
            'F10': [this?.k?.['kbbase'], "0x00", "0x43"],
            'F11': [this?.k?.['kbbase'], "0x00", "0x44"],
            'F12': [this?.k?.['kbbase'], "0x00", "0x45"],
            'Delete': [this?.k?.['kbbase'], "0x00", "0x4c"],
            'Home': [this?.k?.['kbbase'], "0x00", "0x4a"],
            'End': [this?.k?.['kbbase'], "0x00", "0x4d"],
            'PageUp': [this?.k?.['kbbase'], "0x00", "0x4b"],
            'PageDown': [this?.k?.['kbbase'], "0x00", "0x4e"],
            'Backquote': [this?.k?.['kbbase'], "0x00", "0x35"], //['`', '~']
            'Digit1': [this?.k?.['kbbase'], "0x00", "0x1e"],
            'Digit2': [this?.k?.['kbbase'], "0x00", "0x1f"],
            'Digit3': [this?.k?.['kbbase'], "0x00", "0x20"],
            'Digit4': [this?.k?.['kbbase'], "0x00", "0x21"],
            'Digit5': [this?.k?.['kbbase'], "0x00", "0x22"],
            'Digit6': [this?.k?.['kbbase'], "0x00", "0x23"],
            'Digit7': [this?.k?.['kbbase'], "0x00", "0x24"],
            'Digit8': [this?.k?.['kbbase'], "0x00", "0x25"],
            'Digit9': [this?.k?.['kbbase'], "0x00", "0x26"],
            'Digit0': [this?.k?.['kbbase'], "0x00", "0x27"],
            'Minus': [this?.k?.['kbbase'], "0x00", "0x2d"], //['-','_']
            'Equal': [this?.k?.['kbbase'], "0x00", "0x2e"], //['=','+']
            'Backspace': [this?.k?.['kbbase'], "0x00", "0x2a"],
            'NumLock': [this?.k?.['kbbase'], "0x00", "0x53"],
            'NumpadDivide': [this?.k?.['kbbase'], "0x00", "0x54"], //['/']
            'NumpadMultiply': [this?.k?.['kbbase'], "0x00", "0x55"], //['*']
            'NumpadSubtract': [this?.k?.['kbbase'], "0x00", "0x56"], //['-']
            'Tab': [this?.k?.['kbbase'], "0x00", "0x2b"],
            'BracketLeft': [this?.k?.['kbbase'], "0x00", "0x2f"], //['[','{']
            'BracketRight': [this?.k?.['kbbase'], "0x00", "0x30"], //[']', '}']
            'Backslash': [this?.k?.['kbbase'], "0x00", "0x31"], //['\', '|']
            'Numpad7': [this?.k?.['kbbase'], "0x00", "0x5f"],
            'Numpad8': [this?.k?.['kbbase'], "0x00", "0x60"],
            'Numpad9': [this?.k?.['kbbase'], "0x00", "0x61"],
            'NumpadAdd': [this?.k?.['kbbase'], "0x00", "0x57"],
            'CapsLock': [this?.k?.['kbbase'], "0x00", "0x39"], //['CAPSLK']
            'KeyA': [this?.k?.['kbbase'], "0x00", "0x04"],
            'KeyB': [this?.k?.['kbbase'], "0x00", "0x05"],
            'KeyC': [this?.k?.['kbbase'], "0x00", "0x06"],
            'KeyD': [this?.k?.['kbbase'], "0x00", "0x07"],
            'KeyE': [this?.k?.['kbbase'], "0x00", "0x08"],
            'KeyF': [this?.k?.['kbbase'], "0x00", "0x09"],
            'KeyG': [this?.k?.['kbbase'], "0x00", "0x0A"],
            'KeyH': [this?.k?.['kbbase'], "0x00", "0x0B"],
            'KeyI': [this?.k?.['kbbase'], "0x00", "0x0C"],
            'KeyJ': [this?.k?.['kbbase'], "0x00", "0x0d"],
            'KeyK': [this?.k?.['kbbase'], "0x00", "0x0e"],
            'KeyL': [this?.k?.['kbbase'], "0x00", "0x0f"],
            'KeyM': [this?.k?.['kbbase'], "0x00", "0x10"],
            'KeyN': [this?.k?.['kbbase'], "0x00", "0x11"], //
            'KeyO': [this?.k?.['kbbase'], "0x00", "0x12"],
            'KeyP': [this?.k?.['kbbase'], "0x00", "0x13"],
            'KeyQ': [this?.k?.['kbbase'], "0x00", "0x14"],  
            'KeyR': [this?.k?.['kbbase'], "0x00", "0x15"],
            'KeyS': [this?.k?.['kbbase'], "0x00", "0x16"],
            'KeyT': [this?.k?.['kbbase'], "0x00", "0x17"],
            'KeyU': [this?.k?.['kbbase'], "0x00", "0x18"],
            'KeyV': [this?.k?.['kbbase'], "0x00", "0x19"],
            'KeyW': [this?.k?.['kbbase'], "0x00", "0x1a"],   
            'KeyX': [this?.k?.['kbbase'], "0x00", "0x1b"],
            'KeyY': [this?.k?.['kbbase'], "0x00", "0x1c"],
            'KeyZ': [this?.k?.['kbbase'], "0x00", "0x1d"],
            'Semicolon': [this?.k?.['kbbase'], "0x00", "0x33"], //[';',':']
            'Quote': [this?.k?.['kbbase'], "0x00", "0x34"], //[''','"']
            'Enter': [this?.k?.['kbbase'], "0x00", "0x28"],
            'ShiftLeft': [this?.k?.['kbbase'], "0x02", "0x00"],
            'Numpad4': [this?.k?.['kbbase'], "0x00", "0x5c"],
            'Numpad5': [this?.k?.['kbbase'], "0x00", "0x5d"],
            'Numpad6': [this?.k?.['kbbase'], "0x00", "0x5e"],
            'Comma': [this?.k?.['kbbase'], "0x00", "0x36"], // [',','<']
            'Period': [this?.k?.['kbbase'], "0x00", "0x37"], // ['.','>']
            'Slash': [this?.k?.['kbbase'], "0x00", "0x38"], // '/','?'
            'ShiftRight': [this?.k?.['kbbase'], "0x20", "0x00"],
            'ArrowUp': [this?.k?.['kbbase'], "0x00", "0x52"],
            'Numpad1': [this?.k?.['kbbase'], "0x00", "0x59"],
            'Numpad2': [this?.k?.['kbbase'], "0x00", "0x5a"],
            'Numpad3': [this?.k?.['kbbase'], "0x00", "0x5b"],
            'ControlLeft': [this?.k?.['kbbase'], "0x01", "0x00"],
            'MetaLeft': [this?.k?.['kbbase'], "0x08", "0x00"],
            'AltLeft': [this?.k?.['kbbase'], "0x04", "0x00"],
            'Space': [this?.k?.['kbbase'], "0x00", "0x2c"],
            'AltRight': [this?.k?.['kbbase'], "0x40", "0x00"],
            'MetaRight': [this?.k?.['kbbase'], "0x80", "0x00"],
            'ControlRight': [this?.k?.['kbbase'], "0x10", "0x00"],
            'ArrowLeft': [this?.k?.['kbbase'], "0x00", "0x50"],
            'ArrowDown': [this?.k?.['kbbase'], "0x00", "0x51"],
            'ArrowRight': [this?.k?.['kbbase'], "0x00", "0x4f"],
            'Numpad0': [this?.k?.['kbbase'], "0x00", "0x62"],
            'NumpadDecimal': [this?.k?.['kbbase'], "0x00", "0x63"],
            'NumpadEnter': [this?.k?.['kbbase'], "0x00", "0x58"],
            "WakeUp": [this?.k?.['fun'], "0x01", "0x00"], 
            'K42': [this?.k?.['kbbase'], "0x00", "0x32"],
            'K45': [this?.k?.['kbbase'], "0x00", "0x64"],
            'K107': [this?.k?.['kbbase'], "0x00", "0x85"],
            'K56': [this?.k?.['kbbase'], "0x00", "0x87"],
            'K133': [this?.k?.['kbbase'], "0x00", "0x88"],
            'K14': [this?.k?.['kbbase'], "0x00", "0x89"],
            'K132': [this?.k?.['kbbase'], "0x00", "0x8a"],
            'K131': [this?.k?.['kbbase'], "0x00", "0x8b"],
            'K151': [this?.k?.['kbbase'], "0x00", "0x90"],
            'K150': [this?.k?.['kbbase'], "0x00", "0x91"],
            "MEDIA": [this?.k?.['kbmedia'], "0x83", "0x01"],//多媒体
            "PLAY": [this?.k?.['kbmedia'], "0xCD", "0x00"],//播放
            "STOP": [this?.k?.['kbmedia'], "0xb7", "0x00"],//播放停止
            "PREV":	[this?.k?.['kbmedia'], "0xb6", "0x00"],//上一曲
            "NEXT": [this?.k?.['kbmedia'], "0xb5", "0x00"],//下一曲
            "VOL_DEC": [this?.k?.['kbmedia'], "0xea", "0x00"],//音量加
            "VOL_ADD": [this?.k?.['kbmedia'], "0xe9", "0x00"],//音量减
            "Mute": [this?.k?.['kbmedia'], "0xe2", "0x00"],//静音
            "WEB": [this?.k?.['kbmedia'], "0x23", "0x02"],//网页
            "WEB_REFRESH": [this?.k?.['kbmedia'], "0x27", "0x02"],//刷新
            "WEB_STOP": [this?.k?.['kbmedia'], "0x26", "0x02"],//网页停止
            "WEB_BACK":	[this?.k?.['kbmedia'], "0x24", "0x02"],//网页返回
            "WEB_FORWARD": [this?.k?.['kbmedia'], "0x25", "0x02"],//网页前进
            "WEB_FAVORITE": [this?.k?.['kbmedia'], "0x2a", "0x02"],//网页收藏
            "SEARCH": [this?.k?.['kbmedia'], "0x21", "0x02"],//搜索
            "WEB_COMPUTER":	[this?.k?.['kbmedia'], "0x94", "0x01"],//我的电脑
            "Calc": [this?.k?.['kbmedia'], "0x92", "0x01"],//计算器0x8a
            "MAIL":	[this?.k?.['kbmedia'], "0x8a", "0x01"],//邮件
            "POWER": [this?.k?.['kbsys'], "0x01", "0x00"], //电源
            "SLEEP": [this?.k?.['kbsys'], "0x02", "0x00"], //睡眠
            "WAKE": [this?.k?.['kbsys'], "0x04", "0x00"], //唤醒
            "OFFICE_OPEN": [this?.k?.['fn'], "0x01", "0x12"], //打开
            "OFFICE_NEW": [this?.k?.['fn'], "0x01", "0x11"], //新建
            "OFFICE_UNDO": [this?.k?.['fn'], "0x01", "0x1d"], //重做
            "OFFICE_SAVE": [this?.k?.['fn'], "0x01", "0x16"], //保存
            "OFFICE_COPY": [this?.k?.['fn'], "0x01", "0x06"], //复制
            "OFFICE_CUT": [this?.k?.['fn'], "0x01", "0x1b"], //剪切
            "OFFICE_PASTE": [this?.k?.['fn'], "0x01", "0x19"], //粘贴
            "OFFICE_FIND": [this?.k?.['fn'], "0x01", "0x09"], //查找
            "OFFICE_CHECKED_ALL": [this?.k?.['fn'], "0x01", "0x04"], //全选
            "OFFICE_DEC": [this?.k?.['fn'], "0x01", "0x2d"], //缩小
            "OFFICE_ADD": [this?.k?.['fn'], "0x01", "0x2e"], //放大
            "MS_LEFT": [this?.k?.['msbase'], "0x01", "0x00"],//左键 
            "MS_RIGHT": [this?.k?.['msbase'], "0x02", "0x00"],//右键
            "MS_MIDDLE": [this?.k?.['msbase'], "0x04", "0x00"],	//中键
            "MS_FORWORD_KEY": [this?.k?.['msbase'], "0x10", "0x00"],// 前进
            "MS_BACK_KEY": [this?.k?.['msbase'], "0x08", "0x00"],//后退
            "MS_DPI_UP": [this?.k?.['msdpi'], "0x01", "0x00"],//DPI +
            "MS_DPI_DOWN": [this?.k?.['msdpi'], "0x02", "0x00"],//DPI -
            "MS_DPI_LOOP ": [this?.k?.['msdpi'], "0x03", "0x00"],//DPI 循环
            // "MS_DPI_SHIFT": [this?.k?.['fn'], "0x01", "0x2d"]
            "OFFICE_LCTRL": [this?.k?.['fn'], "0x01"], //LCTRL
            "OFFICE_RCTRL": [this?.k?.['fn'], "0x10"], //RCTRL
            "OFFICE_LSHIFT": [this?.k?.['fn'], "0x02"], //LSHIFT
            "OFFICE_RSHIFT": [this?.k?.['fn'], "0x20"], //RSHIFT
            "OFFICE_LALT": [this?.k?.['fn'], "0x04"], //LALT
            "OFFICE_RALT": [this?.k?.['fn'], "0x40"], //RALT
            "OFFICE_LWIN": [this?.k?.['fn'], "0x08"], //LWIN
            "OFFICE_RWIN": [this?.k?.['fn'], "0x80"], //RWIN
            "HONG":[this?.k?.['hong1']],
        };
    }
}



export const other = [
    {
        key: 'kb_6key_mode',
        text: 'kb_6key_mode', 
        component: 'switch',
    },
    {
        key: 'win_flag',
        text: 'win_flag', 
        component: 'switch'
    },
    {
        key: 'D24G_OFF_LED_TIME',
        text: 'D24G_OFF_LED_TIME', 
        component: 'defineTime'
    },
    {
        key: 'BLE_OFF_LED_TIME',
        text: 'BLE_OFF_LED_TIME', 
        component: 'defineTime'
    },
    {
        key: 'D24G_SLEEP_TIME',
        text: 'D24G_SLEEP_TIME', 
        component: 'defineTime'
    },
    {
        key: 'BLE_SLEEP_TIME',
        text: 'BLE_SLEEP_TIME', 
        component: 'defineTime'
    },
    {
        key: 'USB_OFF_LED_TIME',
        text: 'USB_OFF_LED_TIME', 
        component: 'defineTime'
    }
    // ,
    // {
    //     key: `8`,
    //     text: '按键灵敏度', 
    //     component: 'otherSlider'
    // }

]


// export const colorType = [
//     {
        
//     }
// ]

const Text = () => {
    return {
        other,
        keysTypes,
        keyTypeNum
    };
    
};

export default Text;