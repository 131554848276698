import React from 'react';
import { Tooltip, Tabs } from 'antd';
import { RedoOutlined } from '@ant-design/icons'
import keysCustom from '../../common/component/setBoardText/data.ts';

import SetBoardText from '../../common/component/setBoardText/setBoardText.tsx';
import SetCompare from '../../common/component/setBoardText/setCompare.tsx';
import SetHong from '../../common/component/setBoardText/setHong.tsx';
import SetMedia from '../../common/component/setBoardText/setMedia.tsx';
import SetMouse from '../../common/component/setBoardText/setMouse.tsx';
import SetOffice from '../../common/component/setBoardText/setOffice.tsx';
import SetSystem from '../../common/component/setBoardText/setSystem.tsx';
import { useSelector, useDispatch } from 'react-redux';
import { keyTypeNum } from '../../common/text.ts';
import { saveRectKey, updateKeyRect, reqcount, testConnect } from '../../store/kdevice/imp.ts'
import { changeKeyRect } from '../../store/setting/imp.ts'

import KeyBoard from '../keys/keyBoard.tsx';
import { debounce } from '../../common/global.ts';

type tabsType = {
    id: number,
    key: string,
    text: string,
    component?: string,
    btns?: any
}
const {TabPane} = Tabs;

const Index = ({t}:any) => {

    const { key_Rect, default_key_Rect} = useSelector((state:any) => state.kdevice);
    const checkedKey = useSelector((state:any) => state.settingReducer.checkedKey);
    const dispatch = useDispatch();
    
    const setinit = (arr:any) => {
        setTimeout(() => {
          if (reqcount === 0) {
            if (arr && arr.length) dispatch(updateKeyRect([...arr]));
            dispatch(changeKeyRect(arr));
          } else {
            setinit(arr);
          }
        }, 500);
    }

    const returnComponent = (type:string, data:any, onClick:Function, t:Function) => {
        if (type === 'setBoardText') return <SetBoardText t={t} data = {data} onClick={(code:string) => onClick(code)}/>;
        else if (type === 'setCompare') return <SetCompare t={t} data = {data} onClick={(code:string, arr:string[]) => onClick(code, arr)}/>;
        else if (type === 'setHong') return <SetHong t={t} onClick={(code:string, arr:string[]) => onClick(code, arr)} />;
        else if (type === 'setMedia') return <SetMedia t={t} data = {data} onClick={(code:string) => onClick(code)} />;
        else if (type === 'setMouse') return <SetMouse t={t} data = {data} onClick={(code:string) => onClick(code)} />;
        else if (type === 'setOffice') return <SetOffice t={t} data = {data} onClick={(code:string) => onClick(code)} />;
        else if (type === 'setSystem') return <SetSystem t={t} data = {data} onClick={(code:string) => onClick(code)} />;
        else return null;
    }

    const handClick = async (value:string, arr:string[] = []) => {
        if (!checkedKey || checkedKey === 'WakeUp') return;
        debounce('changeKeyBoard', async() => {
            const tag = await testConnect();
            if (!tag?.connect) {
                dispatch(tag);
                return;
            }
            
            const codeValue:any = keyTypeNum.getCodeValue();

            const checkedcodearr = codeValue[checkedKey];
            const index = default_key_Rect?.findIndex((item:number, i:number) => 
                item === parseInt(checkedcodearr?.[2], 16)
                && default_key_Rect[i-1] === parseInt(checkedcodearr?.[1], 16)
                && default_key_Rect[i-2] === parseInt(checkedcodearr?.[0], 16)
            );

            const codearr = value ? codeValue[value]:arr;
            let newRect = [...key_Rect];
            if (index && codearr?.length > 2) {
                newRect[index - 2] = parseInt(codearr[0], 16); 
                newRect[index - 1] = parseInt(codearr[1], 16); 
                newRect[index] = parseInt(codearr[2], 16); 
                saveRectKey(newRect);  
            }
            setinit(newRect);
        }, 500);
    }

    const restKeyRect = async () => {
        debounce('restKeyRect', async() => {
            const tag = await testConnect();
            if (!tag?.connect) {
                dispatch(tag);
                return;
            }
            saveRectKey(default_key_Rect);
            setinit(default_key_Rect);
        }, 500)
    }

    return (
        <div className='custom'>
            <div className='board'>
                <KeyBoard t={t} />
            </div>
            <div className='keys'>
                <div className='rest-btn'>
                    <Tooltip placement="topLeft"
                        title={t(`restDefult`)}>
                        <RedoOutlined onClick={restKeyRect} />
                    </Tooltip>
                </div>
                <Tabs defaultActiveKey="1" tabPosition={'left'} style={{
                    height:'32vh',
                    // overflowY: 'scroll',
                    marginTop: 12
                    }}>
                    {keysCustom.map((item:tabsType) => (
                        <TabPane tab={t(item.key)} key={item.id}>
                            {returnComponent(item.component||"", item?.btns, handClick, t)}
                        </TabPane>
                    ))}
                </Tabs>
            </div>
       </div>
    )
}

Index.displayName = 'Custom';
export default Index;