import React, { useState } from 'react';
import {Row, Col, Button} from 'antd'
import {InputBtns} from '../../../common/component/btns/btns.tsx';
import {tenTo16} from '../../../common/global.ts'
import { keyTypeNum } from '../../../common/text.ts';

const SetCompare = ({data, onClick, t}:any) => {
    const [btn, setBtn] = useState<string>();
    const [checkedBtns, setCheckedBtns] = useState<string[]>([]);
    const changF = () => {
        return data ? [...data].map((item, i) => ({
            key: i,
            text: `${item.Pointer[0]}`,
            value: item.code
        })):[]
    }
    function dolisten(e:any) {
        setBtn(e.code);
        handClick("", e.code);
        removelisten();
    }
    const setlisten = function (){
        document.addEventListener('keydown', dolisten);
    }
    const removelisten = function (){
        document.removeEventListener('keydown', dolisten);
    }
    const handClick = (val:string = "", _btn:string="") => {
        if (!val && !_btn) return;
        let arr:string[];

        if (!val) arr = [...checkedBtns];
        else if(checkedBtns.includes(val)) arr = checkedBtns.filter((item) => item !== val);
        else arr = [...checkedBtns, val];

        if (onClick) {
            const codeValue:any = keyTypeNum?.getCodeValue();
            let val2:any = 0;
            arr.forEach((item:string) => {
                val2 = val2|parseInt(codeValue[item][1], 16);
            });
            onClick("", [keyTypeNum.k?.[`fn`], tenTo16(val2), _btn? codeValue[_btn][2]:'0x00']);
        }

        setCheckedBtns(arr);
     }

    //  useEffect(() => {
    //     if (onClick) {
    //         const codeValue:any = keyTypeNum?.getCodeValue();
    //         let val2:any = 0;
    //         checkedBtns.forEach((item:string) => {
    //             val2 = val2|parseInt(codeValue[item][1], 16);
    //         });
    //         console.log('-----------------------')
    //         console.log(`${val2}`)
    //         console.log('-----------------------')
    //         onClick("", [keyTypeNum.k?.[`fn`], tenTo16(val2), btn? codeValue[btn][2]:'0x00']);
    //     }
    //  }, [btn, onClick, checkedBtns]);

    return <div className='key-board' style={{width: '100%'}}>
        <Row style={{width: '100%'}}>
            <Col style={{textAlign: 'right'}}>
                <div style={{width: 150}}>
                    {InputBtns(changF(), (val:any) => handClick && handClick(val, btn), checkedBtns)}
                </div>
            </Col>
            <Col style={{display: 'flex', alignItems: 'center',textAlign: 'center'}}>
                <div style={{width: 150,fontSize:36}}>
                    {`+`}
                </div>
            </Col>
            <Col style={{display: 'flex', alignItems: 'center',textAlign: 'center'}}>
                <Button className='input-Btn'
                    onClick={setlisten}
                    onMouseLeave={removelisten}>
                        {`${btn? (t(btn) ? t(btn):btn):"-"}`}
                </Button>
            </Col>
        </Row>
    </div>
}

SetCompare.displayName = 'SetCompare';
export default SetCompare;
